import React, { Component } from "react";
import { Button, Form, Col, Card, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import back from '../images/backarrow.png';
import axios from 'axios';
import fileupload from '../images/file-upload-svgrepo-com.svg';
import { config } from '../Config/Config';
import { enqueueSnackbar } from "notistack";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
import { RiSafariFill } from "react-icons/ri";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validEmailRegex =
    /^[a-z0-9+_.-]+@[a-z0-9.-]+$/
const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/
const validPhoneNoRegex = RegExp(
    /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/i
);
// const validateForm = errors => {
//     let valid = true;
//     Object.values(errors).forEach(val => val.length > 0 && (valid = false));
//     return valid;
// };
export default class RequestForAdvertisement extends Component {
    constructor(props) {
        super(props);
        this.imgRef = React.createRef();
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            typeOfAdvertisement: "",
            description: "",
            timingType: "",
            paymentType: "",
            advType: "",
            startDate: "",
            campaignName: "",
            name: "",
            designation: "",
            campaignUrl: "",
            image: "",
            bannerImg: "",
            advTypes: [],
            endDate: "",
            phoneNumber: "",
            clicks: "",
            views: "",
            userId: "",
            email: "",
            clickAmount: "",
            viewAmount: "",
            typeOfAdvertisementError: '',
            descriptionError: '',
            paymentTypeError: '',
            startDateError: '',
            campaignNameError: "",
            campaignUrlError: "",
            nameError: "",
            phoneNumberError: "",
            emailError: "",
            totalAmount: "0",
            home: false,
            agree: false,
            clicksData: [],
            viewsData: [],
            error: "Please select the details"
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        // this.handleBlur = this.handleBlur.bind(this);
        // this.validateDescription = this.validateDescription.bind(this);
        // this.validatePaymentType = this.validatePaymentType.bind(this);
        // this.validateStartDate = this.validateStartDate.bind(this);
        // this.validateCampaignName = this.validateCampaignName.bind(this);
        // this.validateName = this.validateName.bind(this);
        // this.validatePhoneNumber = this.validatePhoneNumber.bind(this);
        // this.validateEmail = this.validateEmail.bind(this);
        // this.validateDesignation = this.validateDesignation.bind(this);
        // this.validateEndDate = this.validateEndDate.bind(this);
        // this.validateCampaignUrl = this.validateCampaignUrl.bind(this);
        // this.validateField = this.validateField.bind(this);
        this.handleContextMenu = this.handleContextMenu.bind(this);
        this.onChangePaymentType = this.onChangePaymentType.bind(this);
    }

    componentDidMount() {
        this.getAdvViewsAndClicks();
        this.getUserDataById();
        this.getAllAdvertismentTypes();
    }
    handleCheck = () => {
        this.setState({ agree: !this.state.agree })
    }
    getAllAdvertismentTypes = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllAdvertismentTypes', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    self.setState({ advTypes: response.data.advTypes })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getAdvViewsAndClicks = () => {
        let headers = {
            "Content-Type": 'application/json',
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAdvViewsAndClicks', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        clicksData: response.data.clicksData,
                        viewsData: response.data.viewsData
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    getUserDataById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getUserDataById', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        name: response.data.userDetails[0]?.name,
                        email: response.data.userDetails[0]?.email,
                        designation: response.data.userDetails[0]?.designation,
                        phoneNumber: response.data.userDetails[0]?.phone
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }
    // componentDidMount() {
    //     this.getOpportunityDetails();
    // }

    handleBlur(event) {
        const { name } = event.target;
        //  this.validateField(name);
        return;
    }

    handleSubmit(event) {
        event.preventDefault();
        // console.log(this.state);
        // let formFileds = [
        //     "typeOfAdvertisement",
        //     "timesOfAdvertisement",
        //     "description",
        //     "paymentType",
        //     "startDate",
        //     "endDate",
        //     "campaignName",
        //     "name",
        //     "phoneNumber",
        //     "email",
        //     "designation",
        //     "campaignUrl"
        // ];
        // let isValid = true;
        // formFileds.forEach(field => {
        //     isValid = this.validateField(field) && isValid;
        // });
        this.saveAdvertisementDetails()
    }
    saveAdvertisementDetails = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "type": this.state.typeOfAdvertisement,
            "advertiserPaymentType": this.state.paymentType,
            "description": this.state.description,
            "startDate": this.state.startDate,
            "endDate": this.state.endDate,
            "campaignUrl": this.state.campaignUrl,
            "imageUrl": this.state.image,
            "bannerImg": this.state.bannerImg,
            "advType": parseInt(this.state.advType),
            "campaignName": this.state.campaignName,
            "advertiserName": this.state.name,
            "advertiserNumber": this.state.phoneNumber,
            "advertiserEmail": this.state.email,
            "designation": this.state.designation,
            "clicks": this.state.paymentType == "Free" ? 0 : parseInt(this.state.clicks),
            "views": this.state.paymentType == "Free" ? 100 : parseInt(this.state.views),
            "amount": parseInt(this.state.totalAmount),
            // "amount": (this.state.paymentType == "Free" ? 0 : 250) + (parseInt(this.state.clicks) * 25),
            // "clickAmount": (this.state.paymentType == "Free" ? 0 : this.state.clicks) * 25,
            // "viewAmount": (this.state.paymentType === "Free" ? 0 : 250),
            // "viewAmount": (this.state.paymentType == "Free" ? 100 : this.state.views) * (this.state.paymentType === "Free" ? 3 : 12),
            "timingType": this.state.timingType
        }
        console.log("payload", payload)
        var self = this
        axios.post(config.userUrl + 'user/requestForAdvertisementByUser', payload, { headers: headers })
            .then(function (response) {
                // console.log("response", response)
                if (response.data.status === true) {
                    //   console.log(response, "requestForAdvertisement created successfully!!")
                    self.setState({ home: true })
                }
            })
            .catch(function (error) {
                console.log("error", payload, error)
                document.getElementById('error').innerText = error
            });
    }
    validateField(name) {
        let isValid = false;
        if (name === "typeOfAdvertisement") isValid = this.validateTypeOfAdvertisement();
        else if (name === "description") isValid = this.validateDescription();
        else if (name === "paymentType") isValid = this.validatePaymentType();
        else if (name === "startDate") isValid = this.validateStartDate();
        else if (name === "campaignName") isValid = this.validateCampaignName();
        else if (name === "name") isValid = this.validateName();
        else if (name === "phoneNumber") isValid = this.validatePhoneNumber();
        else if (name === "email") isValid = this.validateEmail();
        else if (name === "endDate") isValid = this.validateEndDate();
        else if (name === "campaignUrl") isValid = this.validateCampaignUrl();
        else if (name === "designation") isValid = this.validateDesignation();
        return isValid;
    }

    validateTypeOfAdvertisement() {
        let typeOfAdvertisementError = "";
        const value = this.state.typeOfAdvertisement;
        if (value.trim() === "") typeOfAdvertisementError = "select type of Advertisement";
        this.setState({
            typeOfAdvertisementError
        });
        return typeOfAdvertisementError === "";
    }


    validateDescription() {
        let descriptionError = "";
        const value = this.state.description;
        if (value.trim() === "") descriptionError = "Please add Description";
        this.setState({
            descriptionError
        });
        return descriptionError === "";
    }
    validatePaymentType() {
        let paymentTypeError = "";
        const value = this.state.paymentType;
        if (value.trim === "") paymentTypeError = "Please select payment type";
        this.setState({
            paymentTypeError
        });
        return paymentTypeError === "";
    }
    validateStartDate() {
        let startDateError = "";
        const value = this.state.startDate;
        if (value.trim === null) startDateError = "Please enter valid date";
        this.setState({
            startDateError
        });
        return startDateError === "";
    }
    validateEndDate() {
        let endDateError = "";
        const value = this.state.endDate;
        if (value.trim === null) endDateError = "Please enter valid date";
        this.setState({
            endDateError
        });
        return endDateError === "";
    }
    validateCampaignName() {
        let campaignNameError = "";
        const value = this.state.campaignName;
        if (value.trim === null) campaignNameError = "Please enter Campaign Name";
        this.setState({
            campaignNameError
        });
        return campaignNameError === "";
    }
    validateCampaignUrl() {
        let campaignUrlError = "";
        const value = this.state.campaignUrl
        if (value.trim === null) campaignUrlError = "Please enter Campaign Url";
        this.setState({
            campaignUrlError
        });
        return campaignUrlError === "";
    }
    validateName() {
        let nameError = "";
        const value = this.state.name;
        if (value.trim() === "") nameError = "Please add Name";
        this.setState({
            nameError
        });
        return nameError === "";
    }
    validateDesignation() {
        let designationError = "";
        const value = this.state.designation;
        if (value.trim() === "") designationError = "Please add designation";
        this.setState({
            designationError
        });
        return designationError === "";
    }
    validatePhoneNumber() {
        let phoneNumberError = "";
        const value = this.state.phoneNumber;
        if (value.trim() === "") phoneNumberError = "Please add Phone Number";
        this.setState({
            phoneNumberError
        });
        return phoneNumberError === "";
    }
    validateEmail() {
        let emailError = "";
        const value = this.state.email;
        if (value.trim() === "") emailError = "Please add email";
        this.setState({
            emailError
        });
        return emailError === "";
    }
    uploadImage = async (image) => {
        let data = new FormData()
        data.append("file", image)
        data.append("upload_preset", "sceem_")
        data.append("cloud_name", "sceem")
        const resp = await fetch(`https://api.cloudinary.com/v1_1/sceem/image/upload`, {
            method: "post",
            body: data
        })
        let res = await resp.json();
        return res.secure_url
    }

    // handleImage = async (e) => {
    //     //console.log("fgh", e.target.files)
    //     if (e.target.files && e.target.files[0]) {
    //         const res = await this.uploadImage(e.target.files[0]);
    //         if (res) {
    //             this.setState({ image: res })
    //         }
    //     }
    // }
    handleLogoImage = async (e) => {
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            console.log('Selected file:', file);
            console.log('File size in bytes:', file.size, 64 * 1024, file.size > (64 * 1024));
            let size = ((file.size / 1024).toFixed(2));
            console.log('File type:', file.type);
            const maxSizeInKB = 64;
            if (file.size > maxSizeInKB * 1024) {
                enqueueSnackbar(`Image size is ${size} KB. It should be less than or equal to ${maxSizeInKB} KB.`, { variant: 'error' });
                return;
            }
            const res = await this.uploadImage(file);
            if (res) {
                this.setState({ image: res });
            }
        } else {
            console.warn('No file selected');
        }
    }
    handleBannerImage = async (e) => {
        // if (e.target.files && e.target.files[0]) {
        //     const file = e.target.files[0];
        //     const img = new Image();
        //     const objectURL = URL.createObjectURL(file);

        //     img.src = objectURL;
        //     img.onload = async () => {
        //         if (img.width <= 240 && img.height <= 120) {
        //             const res = await this.uploadImage(file);
        //             if (res) {
        //                 this.setState({ bannerImg: res });
        //             }
        //         } else {
        //             enqueueSnackbar('Image must be 120x60 pixels.', { variant: 'error' });
        //         }
        //         URL.revokeObjectURL(objectURL);
        //     };
        // }
        if (e.target.files && e.target.files[0]) {
            const file = e.target.files[0];
            let size = ((file.size / 1024).toFixed(2));
            const maxSizeInKB = 270;
            if (file.size > maxSizeInKB * 1024) {
                enqueueSnackbar(`Image size is ${size}. It should be less than or equal to ${maxSizeInKB} KB.`, { variant: 'error' });
                return;
            }
            const res = await this.uploadImage(file);
            if (res) {
                this.setState({ bannerImg: res });
            }
        } else {
            console.warn('No file selected');
        }
    }
    // changeEndDate(date) {
    //     this.setState({ endDate: date })
    // }
    // calculateEndDate = (startDate) => {
    //     const endDate = new Date(startDate);
    //     //endDate.setMonth(endDate.getMonth() + 3);
    //     endDate.setDate(endDate.getDate() + 90);
    //     return endDate;
    // };

    // changeStartDate = (date) => {
    //     this.setState({ startDate: date });
    //     if (this.state.paymentType === "Paid") {
    //         console.log("date", date)
    //     } else {
    //         const calculatedEndDate = this.calculateEndDate(date);
    //         this.setState({ endDate: calculatedEndDate });
    //     }
    // };
    calculateEndDate = (startDate) => {
        if (!startDate || isNaN(startDate.getTime())) return null; // Check if startDate is valid
        const endDate = new Date(startDate);
        endDate.setDate(endDate.getDate() + 90); // Adding 90 days for Free
        return endDate;
    };

    changeStartDate = (date) => {
        if (!date || isNaN(date.getTime())) return;
        this.setState({ startDate: date }, () => {
            if (this.state.paymentType === "Free") {
                const calculatedEndDate = this.calculateEndDate(date);
                this.setState({ endDate: calculatedEndDate }, this.calculateCost);
            } else {
                this.calculateCost();
            }
        });
    };
    changeEndDate = (date) => {
        if (!date || isNaN(date.getTime())) return;
        this.setState({ endDate: date }, this.calculateCost);
    };
    // onChangePaymentType = (event) => {
    //     const paymentType1 = event.target.value;
    //     const paymentType = event.target.value;
    //     this.setState({ paymentType: event.target.value }, () => {
    //         const { startDate } = this.state;
    //         if (paymentType === "Paid") {
    //             console.log("payment",paymentType)
    //         } else {
    //             const calculatedEndDate = this.calculateEndDate(startDate);
    //             this.setState({ endDate: calculatedEndDate });
    //         }
    //     });
    // };
    onChangePaymentType = (event) => {
        const paymentType = event.target.value;
        console.log("Selected payment type:", paymentType); // Debugging log
        this.setState({ paymentType }, () => {
            const { startDate, endDate, campaignName, clicksData, viewsData } = this.state;

            if (paymentType === "Free") {
                const calculatedEndDate = this.calculateEndDate(startDate);
                this.setState({ endDate: calculatedEndDate, clicks: this.state.clicks, views: this.state.views, totalAmount: 0 });
            } else {
                // Reset endDate for Paid
                const firstClickValue = clicksData[0]?.clicks || 0;
                const firstViewsValue = viewsData[0]?.views || 0;
                this.setState({ endDate: null, clicks: firstClickValue, views: firstViewsValue }, this.calculateCost);
            }
        });
    };
    handleContextMenu = (e) => {
        e.stopPropagation();
    };
    handleViewChange = (e) => {
        const value = e.target.value;
        if (this.state.paymentType === "Free") {
            this.setState({ views: 100 });
        } else {
            const parsedValue = parseInt(value, 10);
            if (!isNaN(parsedValue) && parsedValue > 0) {
                this.setState({ views: parsedValue }, this.calculateCost);
            }
        }
    };
    handleClickChange = (e) => {
        const value = e.target.value;
        if (this.state.paymentType === "Free") {
            this.setState({ clicks: 0 });
        } else {
            const parsedValue = parseInt(value, 10);
            if (!isNaN(parsedValue) && parsedValue > 0) {
                this.setState({ clicks: parsedValue }, this.calculateCost);
            }
        }
    };
    calculateCost = () => {
        const { paymentType, startDate, endDate, clicks } = this.state;
        let months = 0;

        // Calculate the number of months between startDate and endDate
        if (paymentType === "Paid" && startDate && endDate) {
            const startMonth = startDate.getMonth();
            const startYear = startDate.getFullYear();
            const endMonth = endDate.getMonth();
            const endYear = endDate.getFullYear();

            months = (endYear - startYear) * 12 + (endMonth - startMonth);
        } else if (paymentType === "Free") {
            months = 0; // Fixed for Free payment type
        }

        const baseCost = 250 * months;
        const clicksCost = clicks > 0 ? clicks * 25 : 0;

        const totalAmount = baseCost + clicksCost;
        this.setState({ totalAmount: totalAmount }); // Update totalAmount in state
        return totalAmount; // Return totalAmount if needed
    };
    render() {
        const { home, image, imgRef, bannerImg, viewsData, startDate, clicksData } = this.state
        const maxEndDate = new Date(startDate);
        maxEndDate.setMonth(maxEndDate.getMonth() + 12);
        const maxFreeEndDate = new Date(startDate);
        // maxFreeEndDate.setMonth(maxFreeEndDate.getMonth() + 3);
        maxFreeEndDate.setDate(maxFreeEndDate.getDate() + 90);
        if (home) {
            return <Redirect to="/home" />
        }
        return (
            <div onContextMenu={this.handleContextMenu}>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <Form onSubmit={this.handleSubmit} style={{ marginTop: "5rem" }}>
                    <Link to="/home"><img alt="" src={back} className="back_arrow_" /></Link><div className="your-fav ml-5" style={{ position: "fixed", top: "3.4vh" }}> <div className="screen-topgap ml-4">Request for Advertisement</div></div>
                    <div className="card-opp-top"></div>
                    <div style={{ marginLeft: "5%", marginRight: "5%", width: "90%" }} >
                        <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                            <Row style={{ width: "100%", marginTop: "-1px" }} className="mb-2 no-gutters">
                                <Col xs={9}>
                                    <div style={{ marginTop: "4px", height: "4.4rem", width: "100%", textAlign: "center", objectFit: "contain", border: "1px solid gray", borderRadius: "10px" }}>
                                        <label htmlFor="upload-button-banner">
                                            {bannerImg != "" ? (
                                                <img src={bannerImg} style={{ marginTop: "3px", marginBottom: "3px", width: "220px", height: "4rem" }} />
                                            ) : (
                                                <>
                                                    <div className="up-load" style={{ marginTop: "3px", fontSize: "12px", fontWeight: 600 }}>Product/Promotion Banner</div>
                                                    <img src={fileupload} style={{ marginTop: "1px", width: "35px" }} />
                                                </>
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            id="upload-button-banner"
                                            name="bannerImage"
                                            ref={imgRef}
                                            onChange={(e) => this.handleBannerImage(e)}
                                        />
                                    </div>
                                </Col>
                                <Col xs={3}>
                                    {/* <label className="mt-1 poll-date" style={{ fontSize: "13px" }}>Upload Logo<span className="red">*</span></label><br />
                                    <div style={{ height: "45px", textAlign: "center", objectFit: "contain" }}>
                                        <label htmlFor="upload-button-logo">
                                            {image != "" ? (
                                                <img src={image} style={{ width: "45px", height: "45px" }} />
                                            ) : (
                                                <>
                                                    <i class="fa fa-upload fa-2x" style={{ cursor: "pointer" }} aria-hidden="true"></i>
                                                    <div className="up-load" style={{ fontSize: "14px" }}></div>
                                                </>
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            id="upload-button-logo"
                                            name="image"
                                            ref={imgRef}
                                            onChange={(e) => this.handleLogoImage(e)}
                                        />
                                    </div> */}
                                    <div style={{ height: "70px", marginTop: "0.2rem", textAlign: "center", objectFit: "contain", border: "1px solid gray", borderRadius: "10px" }}>
                                        <label htmlFor="upload-button-logo">
                                            {image != "" ? (
                                                <img src={image} style={{ marginTop: "0.05rem", width: "67px", height: "68px" }} />
                                            ) : (
                                                <>
                                                    <div style={{ marginTop: "-1px", fontSize: "12px", fontWeight: 600 }}>Upload</div>
                                                    <img src={fileupload} style={{ width: "29px" }} />
                                                    <div style={{ marginTop: "-1px", fontSize: "12px", fontWeight: 600 }}>Logo</div>
                                                    {/* <i class="fa fa-upload fa-2x" style={{ cursor: "pointer" }} aria-hidden="true"></i> */}
                                                </>
                                            )}
                                        </label>
                                        <input
                                            type="file"
                                            style={{ display: "none" }}
                                            id="upload-button-logo"
                                            name="image"
                                            ref={imgRef}
                                            onChange={(e) => this.handleLogoImage(e)}
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        </Card>
                        <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >

                            <div>
                                <input type="text"
                                    className="form-control"
                                    style={{ resize: "none", fontSize: "12px" }}
                                    value={this.state.campaignName}
                                    name="campaignName"
                                    placeholder="Campaign Name"
                                    onChange={this.onChange}
                                    onBlur={this.handleBlur}
                                />
                                <input type="text"
                                    className="form-control"
                                    style={{ resize: "none", fontSize: "12px" }}
                                    value={this.state.campaignUrl}
                                    name="campaignUrl"
                                    placeholder="Campaign Url"
                                    onChange={this.onChange}
                                    onBlur={this.handleBlur}
                                />
                            </div>
                            <textarea rows={5} type="text"
                                className="form-control"
                                style={{ resize: "none", fontSize: "12px" }}
                                value={this.state.description}
                                name="description"
                                placeholder="Description"
                                onChange={this.onChange}
                                onBlur={this.handleBlur}
                            />
                            {/* <label className="ml-1 mt-1 poll-date" style={{ fontSize: "14px" }}>Upload Banner Image<span className="red">*</span></label><br /> */}

                            <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                <Col xs={6}>
                                    <select
                                        name="typeOfAdvertisement"
                                        className="form-control form-size"
                                        value={this.state.typeOfAdvertisement}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select Business type</option>
                                        <option value={"Commercial"}>Commercial</option>
                                        <option value={"Non-commercial"}>Non-commercial</option>
                                    </select>
                                </Col>
                                <Col xs={6}>
                                    <select
                                        name="advType"
                                        className="form-control form-size"
                                        value={this.state.advType}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select adv type</option>
                                        {this.state.advTypes.map(data =>
                                            <option value={data.id}>{data.advType}</option>
                                        )}
                                    </select>
                                </Col>
                            </Row>
                            <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                <Col xs={6}>
                                    <select
                                        name="paymentType"
                                        className="form-control form-size"
                                        value={this.state.paymentType}
                                        onChange={this.onChangePaymentType}
                                    >
                                        <option value="">Select payment</option>
                                        <option value={"Free"}>Free (Max 3M)</option>
                                        <option value={"Paid"}>Paid (Max 12M)</option>
                                    </select>
                                </Col>
                                <Col xs={6}>
                                    <select
                                        name="timingType"
                                        className="form-control form-size"
                                        value={this.state.timingType}
                                        onChange={this.onChange}
                                    >
                                        <option value="">Select Timing type</option>
                                        <option value={"Omni time"}>Omni time</option>
                                        <option value={"Prime time"}>Prime time</option>
                                    </select>
                                </Col>
                            </Row>
                            <Row style={{ width: "100%", marginTop: "-3px" }}>
                                <Col xs={6}>
                                    <DatePicker
                                        name="startDate"
                                        className="form-control form-bg-adv-date"
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.startDate}
                                        minDate={new Date()}
                                        onChange={this.changeStartDate}
                                        placeholderText="Start date"
                                    />
                                </Col>
                                <Col xs={6}>
                                    <DatePicker
                                        name="endDate"
                                        className="form-control form-bg-adv-date"
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.endDate}
                                        // minDate={new Date(Date.now() + 5 * 86400000)}
                                        maxDate={this.state.paymentType === "Paid" ? maxEndDate : maxFreeEndDate}
                                        onChange={this.state.paymentType === "Free" ? null : (date) => this.changeEndDate(date)}
                                        // filterDate={(date) => date.getDay() === 5}
                                        placeholderText="End date"
                                    />
                                </Col>
                            </Row>

                            {/* <DatePicker
                                        name="endDate"
                                        className="form-control form-bg-adv-date"
                                        dateFormat="dd/MM/yyyy"
                                        selected={this.state.endDate}
                                        minDate={new Date(Date.now() + 5 * 86400000)}
                                        onChange={(date) => this.changeEndDate(date)}
                                        filterDate={(date) => date.getDay() === 5}
                                        placeholderText="End date"
                                    /> */}
                            {/* <div className="form-group">
                                <div className="ml-1 form-check form-check-inline mt-1 form_check" style={{ display: "flex" }}>
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        Commercial
                                    </label>
                                    <input name="typeOfAdvertisement" className="ml-3  form-check" type="radio" value="non-commercial" checked={this.state.typeOfAdvertisement === "non-commercial"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        Non-commercial
                                    </label>
                                </div>
                                <div className="ml-1 form-check form-check-inline form_check mt-2" style={{ display: "flex" }}>
                                    <input name="timesOfAdvertisement" className="form-check" type="radio" value="3" checked={this.state.timesOfAdvertisement === "3"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        3 Mon
                                    </label>
                                    <input name="timesOfAdvertisement" className="ml-2 form-check" type="radio" value="6" checked={this.state.timesOfAdvertisement === "6"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        6 Mon
                                    </label>
                                    <input name="timesOfAdvertisement" className="ml-2 form-check" type="radio" value="9" checked={this.state.timesOfAdvertisement === "9"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        9 Mon
                                    </label>
                                    <input name="timesOfAdvertisement" className="ml-2 form-check" type="radio" value="12" checked={this.state.timesOfAdvertisement === "12"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        12 Mon
                                    </label>
                                </div>
                                <div className="ml-1 form-check form-check-inline form_check mt-2" style={{ display: "flex" }}>
                                    <input name="paymentType" className="form-check" type="radio" value="free" checked={this.state.paymentType === "free"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        Free
                                    </label>
                                    <input name="paymentType" className="ml-3 form-check" type="radio" value="paid" checked={this.state.paymentType === "paid"} onChange={this.onChange} />
                                    <label class="ml-1 form-check-label" for="flexRadioDefault1">
                                        Paid
                                    </label>
                                </div> */}

                            <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                <Col xs={6}>

                                    {this.state.paymentType === "Paid" ? (
                                        <div style={{  marginTop:"-1px",display: 'flex', alignItems: 'center' }}>
                                            <span style={{ width: '60%', marginLeft: '2px', marginRight: '2px', fontSize: "10px" }}>Views in duration</span>
                                            <select
                                                name="views"
                                                className="form-control form-size"
                                                style={{ width: "40%", paddingLeft: "2px", paddingRight: "2px" }}
                                                value={this.state.views}
                                                onChange={this.handleViewChange}
                                            >
                                                {viewsData.map(option => (
                                                    <option key={option.id} value={option.views}>
                                                        {option.views}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    ) : (
                                        <div style={{  marginTop:"-1px",display: 'flex', alignItems: 'center' }}>
                                            <span style={{ width: '60%', marginLeft: '2px', marginRight: '2px', fontSize: "10px" }}>Views in duration</span>
                                            <input
                                                type="number"
                                                name="views"
                                                className="form-control form-size"
                                                style={{ width: "40%", paddingLeft: "5px", paddingRight: "5px" }}
                                                value={this.state.paymentType === "Free" ? 100 : ""}
                                                disabled
                                            />
                                        </div>

                                    )}


                                </Col>
                                <Col xs={6}>
                                    {this.state.paymentType === "Paid" ? (
                                        <div style={{ marginTop:"-1px",display: 'flex', alignItems: 'center' }}>
                                            <span style={{ width: '60%', marginLeft: '2px', marginRight: '2px', fontSize: "10px" }}>Clicks in duration</span>
                                            <select
                                                name="views"
                                                className="form-control form-size"
                                                style={{ width: "40%", paddingLeft: "2px", paddingRight: "2px" }}
                                                value={this.state.clicks}
                                                onChange={this.handleClickChange}
                                            >
                                                {clicksData.map(option => (
                                                    <option key={option.id} value={option.clicks}>
                                                        {option.clicks}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                    ) : (
                                        <div style={{ marginTop:"-1px",display: 'flex', alignItems: 'center' }}>
                                            <span style={{ width: "60%", marginLeft: '2px', marginRight: '2px', fontSize: "10px" }}>Clicks in duration</span>
                                            <input
                                                type="number"
                                                name="clicks"
                                                className="form-control form-size"
                                                style={{ width: "40%", paddingLeft: "7px", paddingRight: "5px" }}
                                                value={this.state.paymentType === "Free" ? 0 : ""}
                                                disabled
                                            />
                                        </div>
                                        // <div style={{ display: 'flex', alignItems: 'center' }}>
                                        //     <span style={{ marginLeft:'2px',marginRight: '2px',fontSize:"10px" }}>Clicks in duration</span>
                                        //     <input
                                        //         type="text"
                                        //         value={this.state.value}
                                        //         onChange={this.handleChange}
                                        //         className="form-control form-size"
                                        //     />
                                        // </div>
                                    )}
                                </Col>
                            </Row>
                            <Row style={{ width: "100%", marginTop: "-3px" }} className="mb-2 no-gutters">
                                <Col xs={12}>
                                    <input type="text"
                                        name="totalAmount"
                                        className="form-control form-size"
                                        value={this.state.paymentType === "Free" ? `Rs.${this.state.totalAmount}` : this.state.paymentType === "Paid" ? `Rs.${this.state.totalAmount}` : "Cost for Duration in (Rs.)"}
                                        // value={`Rs.${this.state.paymentType === "Free" ? '0' : (250 + (this.state.clicks > 0 ? parseInt(this.state.clicks) * 25 : 0))}`}

                                        // value={`Rs.` + ((this.state.paymentType == "Free" ? 100 : this.state.views) * (this.state.paymentType === "Free" ? 3 : 12)) + "/mon"}
                                        disabled={true} />
                                </Col>
                                {/* <Col xs={6}>
                                    <input type="text"
                                        name="clickAmount"
                                        className="form-control form-size"
                                        value={`Rs.` + ((this.state.paymentType == "Free" ? 0 : this.state.clicks) * 25)}
                                        style={{ marginRight: '5px' }}
                                        disabled={true} />


                                </Col> */}
                            </Row>
                        </div>
                        </Card ><br />
                        <div className="ml-2 mr-1" style={{ display: 'flex', alignItems: 'center' }}>
                            <input
                                type="checkbox"
                                style={{ accentColor: "#31197C" }}
                                checked={this.state.agree}
                                onClick={this.handleCheck}
                            />
                            <span style={{ fontWeight: "700", fontSize: "13px", marginLeft: "8px", marginTop: 0 }}>
                                I agree that this advertisement is only for business purposes.
                            </span>
                        </div>

                        {/* <Card className="createopportunity-card">
                            <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                                <Row style={{ width: "100%" }} className="no-gutters">
                                    <Col xs={6}>
                                        <input type="text"
                                            className="form-control form-size"
                                            aria-describedby="text"
                                            value={this.state.name}
                                            readOnly
                                            name="name"
                                            placeholder="Name"
                                            onChange={this.onChange}
                                            onBlur={this.handleBlur}
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <input type="text"
                                            className="form-control form-size"
                                            aria-describedby="text"
                                            value={this.state.designation}
                                            name="designation"
                                            readOnly
                                            placeholder="Designation"
                                            onChange={this.onChange}
                                            onBlur={this.handleBlur}
                                        />
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters mb-2">
                                    <Col xs={6}>
                                        <input type="email"
                                            className="form-control form-size"
                                            aria-describedby="text"
                                            value={this.state.email}
                                            name="email"
                                            readOnly
                                            placeholder="Email"
                                            onChange={this.onChange}
                                            onBlur={this.handleBlur}
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <input type="number"
                                            className="form-control form-size"
                                            aria-describedby="text"
                                            value={this.state.phoneNumber}
                                            name="phoneNumber"
                                            readOnly
                                            placeholder="Contact person phone"
                                            onChange={this.onChange}
                                            onBlur={this.handleBlur}
                                        />
                                    </Col>
                                </Row>
                            </div>
                        </Card> */}

                    </div >
                    {/* <div className="ml-4"><input type="checkbox" checked={this.state.checkboxToshowDetails} onClick={this.handleCheck} /> <span className="signup-agree-text"> &nbsp;Show contact details</span></div> */}
                    <center>
                        {/* <div id="status" className="errormsg"></div> */}
                        {/* <div id="error" className="errormsg"></div> */}
                        {((this.state.campaignName !== "") && (this.state.campaignUrl !== "") && (this.state.description !== "") && (this.state.typeOfAdvertisement !== "") && (this.state.advType !== "") && (this.state.timingType !== "") && (this.state.paymentType !== "") && (this.state.startDate !== "") && (this.state.endDate !== "") &&
                            (this.state.clicks !== null && this.state.clicks !== undefined && this.state.clicks >= 0) && (this.state.agree) &&
                            (this.state.views !== null && this.state.views !== undefined && this.state.views >= 0)) ?
                            <Button variant="default" type="submit" className="btn-block opportunity-btn mt-2">Raise a Request</Button>
                            : <Button variant="default" type="submit" disabled={true} className="btn-block opportunity-btn mt-2">Raise a Request</Button>}
                        <br />
                        {/* <Button variant="default" type="submit" className="btn-block opportunity-btn mt-1" disabled={
                            !(
                                this.state.campaignName &&
                                this.state.campaignUrl &&
                                this.state.description&&
                                this.state.name &&
                                this.state.advertiserNumber
                            )
                        }

                        >Raise a Request</Button> */}

                    </center>
                </Form >
            </div >
        );
    }
}
