import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Modal } from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import { enqueueSnackbar } from 'notistack'
import article from '../images/article-new.jpeg';
import smefullday from '../images/smefullday.svg';
import smemorning from '../images/smemorning.svg';
import smeafternoon from '../images/smeafternoon.svg';
import smebookings from '../images/smebookings.svg';
import smerating from '../images/smerating.svg';
import orangestar from '../images/Orangestar.svg';
import bluestar from '../images/Bluestar.svg';
import assessmentone from '../images/assessment1.svg';
import assessmenttwo from '../images/assessment2.svg';
import assessmentthree from '../images/assessment3.svg';
import assessmentfour from '../images/assessment4.svg';
import assessmentfive from '../images/assessment5.svg';
import bluetick from '../images/bluetick.svg';
import googlelinkImg from '../images/googlelink.svg';
import linktext from '../images/linktext.svg';
import tick from '../images/tick.svg';
import cross from '../images/cross.svg';
import moment from 'moment-timezone';
import question from '../images/questionmark.svg';
import nodata from '../images/no-data.png';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default function SMEPlanned(props) {
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [slots, setSlots] = useState([]);
    const [selectedDate, setSelectedDate] = useState()
    const [currDate, setCurrDate] = useState()
    const [allOfferings, setAllOfferings] = useState([])
    const [topics, setTopics] = useState([])
    const [showPopup, setShowPopup] = useState(false)
    const [popupData, setPopupData] = useState([])
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showAssessmentPopup, setShowAssessmentPopup] = useState(false)
    const [showCompletedAssessmentPopup, setShowCompletedAssessmentPopup] = useState(false)
    const [showEventAssessmentPopup, setShowEventAssessmentPopup] = useState(false)
    const [showCompletedEventAssessmentPopup, setShowCompletedEventAssessmentPopup] = useState(false)
    const today = new Date();

    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const images = [
        { src: assessmentone, value: 1 },
        { src: assessmenttwo, value: 2 },
        { src: assessmentthree, value: 3 },
        { src: assessmentfour, value: 4 },
        { src: assessmentfive, value: 5 }
    ];
    const [showEventPopup, setShowEventPopup] = useState(false)
    const [eventsData, setEventsData] = useState([]);
    const monthsData = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];

    const currentMonthIndex = new Date().getMonth();
    const [selectedMonth, setSelectedMonth] = useState(currentMonthIndex);

    // { id: 1, "time": "8 AM", "name": "Kashturi", "status": 1, "value": 1, "offering": "Mentorship" },
    // { id: 2, "time": "10 AM", "name": "Chandra Sekhar", "status": 2, "value": 2, "offering": "Expertise" },
    // { id: 3, "time": "2 PM", "name": "Srigowri", "status": 1, "value": 2, "offering": "Mentorship" },



    useEffect(() => {
        const formattedDate = `${today.getDate()}-${months[today.getMonth()]}-${today.getFullYear()}`;
        setCurrDate(props?.location?.state?.selectedDate ? props?.location?.state?.selectedDate : formattedDate)
        // getSmeUserData();
        // getAllSmeOfferings();
        // getAllDays();
        // getAllTimeSlots();
        // console.log("userId",userId)
        getAllSmeOfferings();
        getAllRegisterTopics();
        getSmeBookings(props?.location?.state?.selectedDate ? props?.location?.state?.selectedDate : formattedDate);
        const eventFormattedDate = props?.location?.state?.selectedDate ?
            new Date(props.location.state.selectedDate) :
            new Date(formattedDate);

        // Extract the month (0-based, so January is 0, December is 11)
        // const eventmonth = eventFormattedDate.getMonth() + 1;
        // console.log("month", eventFormattedDate.getMonth())
        getUserRegisteredSmeEvents(props?.location?.state?.selectedDate ? props?.location?.state?.selectedDate : formattedDate);
        //  console.log("location", props?.location?.state?.selectedDate ? props?.location?.state?.selectedDate : formattedDate)
    }, [])
    const getUserRegisteredSmeEvents = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "bookingDate": moment(id).format("YYYY-MM-DD")
        }
        axios.post(config.userUrl + 'user/getUserRegisteredSmeEvents', payload, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    setEventsData(response.data.eventsData)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    // const changeEventStatusBySme = (id) => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     var payload = {
    //         "status": 2,
    //         "id": id
    //     }
    //     axios.post(config.userUrl + 'user/changeEventStatusBySme', payload, { headers: headers })
    //         .then(function (response) {
    //             // console.log(response);
    //             if (response.data.status === true) {
    //                 getSmeCreatedEventsData(currentMonthIndex)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // };
    const handleMonthChange = (e) => {
        setSelectedMonth(parseInt(e.target.value, 10));
        getUserRegisteredSmeEvents(parseInt(e.target.value, 10));

    };
    const handleEventPopup = (data) => {
        setShowEventPopup(true)
        setPopupData(data)
    }
    const handleShowAssessment = (data) => {
        if (moment(data.bookingDate).isBefore(moment(), 'day')) {
            //  console.log("2")
            setShowAssessmentPopup(true)
            setPopupData(data)
        }
    }
    const handleShowCompletedAssessment = (data) => {
        setShowCompletedAssessmentPopup(true)
        setPopupData(data)
    }
    const handleCloseAssessment = () => {
        setShowAssessmentPopup(false)
        setSelectedImage(null)
        setSelectedOption(null)
    }
    const handleCloseCompletedAssessment = () => {
        setShowCompletedAssessmentPopup(false)
    }
    const handleImageClick = (value) => {
        setSelectedImage(value);
    };

    const handleTickClick = () => {
        setSelectedOption(true);
    };

    const handleCrossClick = () => {
        setSelectedOption(false);
    };

    const handleShowEventAssessment = (data) => {
        if (moment(data.bookingDate).isBefore(moment(), 'day')) {
            //  console.log("2")
            setShowEventAssessmentPopup(true)
            setPopupData(data)
        }
    }
    const handleShowCompletedEventAssessment = (data) => {
        setShowCompletedEventAssessmentPopup(true)
        setPopupData(data)
    }
    const handleCloseEventAssessment = () => {
        setShowEventAssessmentPopup(false)
        setSelectedImage(null)
        setSelectedOption(null)
    }
    const handleCloseCompletedEventAssessment = () => {
        setShowCompletedEventAssessmentPopup(false)
    }
    const handleDateChange = date => {
        setSelectedDate(date);
        //console.log("date", date)
        const formattedDate = date ? formatDate(date) : null;
        //console.log(formattedDate);
        // setFormattedNewDate(formattedDate)
        getSmeBookings(date)
        getUserRegisteredSmeEvents(date);
    };
    const getAllRegisterTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllRegisterTopics', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    setTopics(response.data.topics)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const formatDate = date => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };
    const getText = (offeringId) => {
        const item = allOfferings.find(txt => txt.id === offeringId);
        return item ? item.text : "";
    }
    const getExpertiseText = (id) => {
        const item = topics.find(txt => txt.id === id);
        return item ? item.topicName : "";
    }
    const timeToValue = (time) => {
        const [hour, minute] = time.split(/:| /);
        let value = parseInt(hour) * 60;
        if (time.includes("PM") && hour !== "12") {
            value += 12 * 60; // Convert to 24-hour format
        }
        value += parseInt(minute);
        return value;
    };
    const getSmeBookings = (date) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "date": moment(date).format("YYYY-MM-DD")
        }
        axios.post(config.userUrl + 'user/getSmeBooking', payload, { headers: headers })
            .then(function (response) {
                //   console.log(response);
                if (response.data.status === true) {
                    let data = response.data.filteredData?.sort((a, b) => {
                        const timeValueA = timeToValue(a.time);
                        const timeValueB = timeToValue(b.time);
                        return timeValueA - timeValueB;
                    });
                    setSlots(data)
                    //   console.log("success")
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    setAllOfferings(response.data.offerings)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    const handlePopup = (data) => {
        // console.log("1")
        setShowPopup(true)
        setPopupData(data)
    }
    const assessmentData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "id": parseInt(popupData?.id),
            "assessment": parseInt(selectedImage),
            "meetingHappened": selectedOption == true ? true : false
        }
        axios.post(config.userUrl + 'user/smeBookingAssessment', payload, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    setShowAssessmentPopup(false)
                    setSelectedImage(null)
                    setSelectedOption(null)
                    getSmeBookings(selectedDate)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const eventAssessmentData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "eventId": parseInt(popupData?.id),
            "assessment": parseInt(selectedImage),
            "meetingHappened": selectedOption == true ? true : false
        }
        axios.post(config.userUrl + 'user/userAssessmentForEvent', payload, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    setShowEventAssessmentPopup(false)
                    setSelectedImage(null)
                    setSelectedOption(null)
                    getUserRegisteredSmeEvents(selectedDate);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const openLinkUrl = (link) => {
        window.open(`${link}`)
    }
    return (
        <div >
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="mt-5">
                <div className="your-jitbits" style={{ display: "flex" }}>Session Planned on <div className="ml-1" style={{ marginTop: "-12px" }}>

                    <DatePicker
                        customInput={<CustomInput />}
                        // selected={selectedDate ? selectedDate : new Date(Date.now())}
                        selected={selectedDate ? selectedDate : (props?.location?.state?.selectedDate) ? props?.location?.state?.selectedDate : new Date(Date.now())}
                        onChange={handleDateChange}
                        className="_picker"
                        style={{ border: "none" }}
                        dateFormat="dd-MMM-yyyy"
                        placeholder="Select Date"
                    />  </div></div>
                <div className="ml-2 mr-2 mb-2 sme-planned-border">
                    {slots.length || eventsData.filter(event => {
                        const eventDate = new Date(event.bookingDate);
                        let selected = selectedDate ? new Date(selectedDate) : new Date(currDate);
                        let newMonth = selected.getMonth();
                        if (newMonth > 11) {
                            selected.setMonth(0);
                            selected.setFullYear(selected.getFullYear() + 1);
                        } else {
                            selected.setMonth(newMonth);
                        }
                        return eventDate.getMonth() === selected.getMonth();
                    }).length > 0 ? (
                        <>
                            {slots.length > 0 && (
                                <div className="mt-2 mb-2">
                                    {slots.map((data, i) => (
                                        <Card
                                            className="sme-planned-card sme-animation"
                                            style={moment(new Date(Date.now())).format("DD-MM-YYYY") === moment(data?.createdAt).format("DD-MM-YYYY")
                                                ? { border: "2px solid #FF942C", "--animation-order": i }
                                                : { border: "2px solid black", "--animation-order": i }}
                                        >
                                            <Row style={{ width: "100%" }} className="no-gutters">
                                                <Col xs={9} onClick={() => handlePopup(data)}>
                                                    <div className="ml-1 planned-title">
                                                        <img src={getText(data.offeringId) + ".svg"} alt={getText(data.offeringId)} /> {data.time} {data.name}
                                                    </div>
                                                </Col>
                                                <Col xs={3}>
                                                    <div style={{ display: "flex" }}>
                                                        {moment(data.bookingDate).isBefore(moment(), 'day') ? (
                                                            <div style={{ display: "flex", gap: "0.5rem", marginTop: "-2px" }}>
                                                                <div>
                                                                    <img src={bluetick} onClick={() => handlePopup(data)} style={{ visibility: "hidden", marginTop: "-4px", width: "34px" }} />
                                                                </div>
                                                                <div onClick={() => data.assessment === null ? handleShowAssessment(data) : handleShowCompletedAssessment(data)}>
                                                                    <img src={data.assessment === null ? orangestar : bluestar} style={{ marginTop: "-4px", width: "34px" }} />
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div style={{ marginTop: "-1px", display: "flex", gap: "0.5rem" }}>
                                                                <div>
                                                                    <span style={{ visibility: "hidden" }}>
                                                                        <img src={tick} style={{ marginTop: "-6px", width: "34px" }} />
                                                                    </span>
                                                                </div>
                                                                <div>
                                                                    {data.status === 1 ? (
                                                                        <span onClick={() => handlePopup(data)}>
                                                                            <img src={tick} style={{ marginTop: "-6px", width: "34px" }} />
                                                                        </span>
                                                                    ) : data.status === 2 ? (
                                                                        <span onClick={() => handlePopup(data)}>
                                                                            <img src={cross} style={{ marginTop: "-6px", width: "34px" }} />
                                                                        </span>
                                                                    ) : (
                                                                        <span onClick={() => handlePopup(data)}>
                                                                            <img src={question} style={{ marginTop: "-6px", width: "34px" }} />
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    ))}
                                </div>
                            )}

                            {eventsData.filter(event => {
                                const eventDate = new Date(event.bookingDate);
                                let selected = selectedDate ? new Date(selectedDate) : new Date(currDate);
                                let newMonth = selected.getMonth();
                                if (newMonth > 11) {
                                    selected.setMonth(0);
                                    selected.setFullYear(selected.getFullYear() + 1);
                                } else {
                                    selected.setMonth(newMonth);
                                }
                                return eventDate.getMonth() === selected.getMonth();
                            }).length > 0 && (
                                    eventsData.map((event, index) => {
                                        const eventMonth = new Date(event.bookingDate).getMonth();
                                        if (eventMonth === new Date(selectedDate ? selectedDate : currDate).getMonth()) {
                                            return (
                                                <div key={event.id} className="link">
                                                    <Card className="mt-2 sme-planned-card sme-animation">
                                                        <Row style={{ width: "100%" }} className="no-gutters">
                                                            <Col xs={10} onClick={() => handleEventPopup(event)}>
                                                                <div className="ml-1 planned-title">
                                                                    <img src={getText(event.offeringId) + ".svg"} alt={getText(event.offeringId)} /> {event.time} &nbsp;({event.registeredcount} participants)
                                                                </div>
                                                            </Col>
                                                            <Col xs={2}>
                                                                <div style={{ display: "flex" }}>
                                                                    {moment(event.bookingDate).isBefore(moment(), 'day') ? (
                                                                        <div style={{ display: "flex", marginTop: "-2px", marginLeft: "16px" }}>
                                                                            <div onClick={() => event.assessment === null ? handleShowEventAssessment(event) : handleShowCompletedEventAssessment(event)}>
                                                                                <img src={event.assessment === null ? orangestar : bluestar} style={{ marginTop: "-4px", width: "35px" }} />
                                                                            </div>
                                                                        </div>
                                                                    ) : (
                                                                        <div style={{ marginTop: "-1px", display: "flex", marginLeft: "12px" }}>
                                                                            <div>
                                                                                {event.status === 1 ? (
                                                                                    <span onClick={() => handleEventPopup(event)}>
                                                                                        <img src={tick} style={{ marginTop: "-6px", width: "34px" }} />
                                                                                    </span>
                                                                                ) : event.status === 2 ? (
                                                                                    <span onClick={() => handleEventPopup(event)}>
                                                                                        <img src={cross} style={{ marginTop: "-6px", width: "34px" }} />
                                                                                    </span>
                                                                                ) : (
                                                                                    <span onClick={() => handleEventPopup(event)}>
                                                                                        <img src={question} style={{ marginTop: "-6px", width: "34px" }} />
                                                                                    </span>
                                                                                )}
                                                                            </div>
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            </Col>
                                                        </Row>
                                                    </Card>
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    })
                                )}
                        </>
                    ) : (
                        <div>
                            <img src={nodata} style={{marginTop:"18%",width:"96%",marginLeft:"2%",marginRight:"2%"}} />
                        <center className="mt-2">No Planned Meeting</center>
                        </div>
                    )}

                    <Modal show={showPopup} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        style={{ margin: 0 }}
                        centered>
                        <Modal.Body style={{ background: "#EFF5FB", borderRadius: "16px", padding: 0 }}>
                            <div className="sme-booking-slot-popup-planned">
                                <div className="ml-1">  <img src={popupData.status === 1 ? tick : popupData.status === 2 ? cross : question} style={{ width: "34px", marginTop: "-3px" }} /></div>
                                <div style={{ fontSize: "18px", marginTop: "-4px", textAlign: "center" }}>{getText(popupData?.offeringId)} Session</div>
                                <div style={{ display: "flex", justifyContent: "space-between", color: "white", marginTop: "-4px", marginRight: "0.8rem", cursor: "pointer" }}> <div></div><i onClick={() => setShowPopup(false)} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>
                            </div>
                            {/* <Modal.Body>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-5px" }}> <div></div><i onClick={() => setShowPopup(false)} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>

                                <center className="sme-slot-popup-heading" style={{ marginTop: "-1.3rem" }}>Booked On: {moment(popupData.bookingDate).format("DD-MMM-YYYY")}</center> */}

                            <Row style={{ width: "100%", marginTop: "2rem", height: "6.2rem" }} className="no-gutters">
                                <Col xs={4}>
                                    <center><img alt="" src={config.userUrl + popupData?.profilePicture} style={{ width: "58px", marginBottom: "5px" }} /></center>
                                    <center style={{ fontSize: "15px", lineHeight: "18px", fontFamily: "Noto Sans", fontWeight: 600 }} className="mt-2">{popupData?.name}</center>
                                </Col>
                                <Col xs={4}>
                                    <center>
                                        <img src={getExpertiseText(popupData?.topicId) + ".png"} alt={popupData.topicId} style={{ width: "55px", marginTop: "3px", marginBottom: "5px" }} />
                                        <div className="mt-2" style={{ lineHeight: "18px", fontSize: "15px", fontFamily: "Noto Sans", fontWeight: 600 }}>{getExpertiseText(popupData?.topicId)}</div>
                                    </center>
                                </Col>
                                <Col xs={4}>
                                    <Card style={{ border: "none", marginRight: "15px", background: "#DCEAF7", borderRadius: "10px", fontWeight: 700, fontFamily: "Noto Sans" }}>
                                        <center>
                                            <div style={{ fontSize: "28px" }}> {moment(popupData.bookingDate).format("DD")}</div>
                                            <div style={{ fontSize: "16px", marginTop: "-4px" }}> {moment(popupData.bookingDate).format("MMM")}</div>
                                            <div style={{ fontSize: "16px", marginTop: "0px" }} className="mb-2"> {popupData.time}</div>
                                        </center>
                                    </Card>
                                </Col>
                            </Row>
                            <div className="mb-1"></div>
                            <div className="sme-booking-slot-popup-bottom">
                                <div style={{ fontSize: "12px", padding: "3px 8px", cursor: "pointer" }} onClick={() => openLinkUrl(popupData.link)}><img src={googlelinkImg} />{popupData.link}</div>
                                <div style={{ fontSize: "12px", padding: "3px 8px" }}><img src={linktext} /><i>{popupData.agenda}</i>
                                </div>
                                <div style={{ paddingBottom: "3px" }}></div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/* assessmentPopup */}
                    <Modal show={showAssessmentPopup} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Body style={{ background: "#EFF5FB", borderRadius: "16px" }}>
                            {/* <div className="sme-booking-slot-popup">
                                <div style={{ fontSize: "18px", textAlign: "center", marginLeft: "-10px" }} className="mt-2">Assessment of {getText(popupData.offeringId)} session </div>
                                <div style={{ display: "flex", justifyContent: "space-between", color: "white", marginTop: "-20px", marginRight: "0.5rem", cursor: "pointer" }}> <div></div><i onClick={() => handleCloseAssessment()} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>
                            </div> */}
                            <div className="sme-booking-slot-popup-planned">
                                <div className="ml-1">  <img src={popupData.status === 1 ? tick : popupData.status === 2 ? cross : question} style={{ width: "34px", marginTop: "-3px" }} /></div>
                                <div style={{ fontSize: "18px", marginTop: "-4px", textAlign: "center" }}>Assessment of Session</div>
                                <div style={{ display: "flex", justifyContent: "space-between", color: "white", marginTop: "-4px", marginRight: "0.8rem", cursor: "pointer" }}> <div></div><i onClick={() => handleCloseAssessment()} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>
                            </div>
                            <div className="sme-profile-bg-booking-req">
                                <Row style={{ width: "100%" }} className="no-gutters">
                                    <Col xs={3}>
                                        <center><img alt="" src={config.userUrl + popupData?.profilePicture} style={{ width: "50px" }} /></center>
                                        <center style={{ fontSize: "14px" }}>{popupData?.name}</center>
                                    </Col>
                                    <Col xs={9}>
                                        <center>
                                            <div style={{ marginTop: "-6px" }}>{getText(popupData.offeringId)} session</div>on {getExpertiseText(popupData?.topicId)}<br />
                                            ({moment(popupData.bookingDate).format("DD-MMM-YYYY")} @ {popupData.time})
                                        </center>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                {/* <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-5px" }}> <div></div><i onClick={() => handleCloseAssessment()} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>
                                <center className="sme-slot-popup-heading" style={{ marginTop: "-1.3rem" }}>{getText(popupData.offeringId)} with {popupData.name}  <br />{moment(popupData.bookingDate).format("DD-MMM-YYYY")} @ {popupData.time}</center> */}
                                <Card style={{ border: "1px solid gray", borderRadius: "6px", padding: "4px" }}>
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col xs={8}>
                                            <div className="">Has the meeting happened?</div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className={`mb-1 ml-2`} onClick={handleTickClick}>
                                                <img src={tick}
                                                    style={{
                                                        marginLeft: "4px",
                                                        marginTop: "-4px",
                                                        width: "30px",
                                                        border: selectedOption === true ? '2px solid #ff942c' : 'none',
                                                        borderRadius: selectedOption === true ? '50%' : '0'
                                                    }} />
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className={`mb-1`} onClick={handleCrossClick}>
                                                <img src={cross} style={{
                                                    marginTop: "-4px",
                                                    width: "30px",
                                                    border: selectedOption === false ? '2px solid #ff942c' : 'none',
                                                    borderRadius: selectedOption === false ? '50%' : '0'
                                                }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                                <div className="mt-2"></div>
                                <Card style={{ border: "1px solid gray", borderRadius: "6px", padding: "4px" }}>
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col xs={6}>
                                            <div className="mb-2 mt-1">Assessment</div>
                                        </Col>
                                        <Col xs={6}>
                                            <div style={{ display: "flex", gap: "2px", flexWrap: "wrap", marginTop: "-2px" }} className="ml-2">
                                                {images.map((image, index) => (
                                                    <div className="mb-1" onClick={() => handleImageClick(image.value)}>
                                                        <img
                                                            src={image.src}
                                                            style={{
                                                                width: "25px",
                                                                height: "25px",
                                                                border: selectedImage === image.value ? '2px solid #ff942c' : 'none',
                                                                borderRadius: selectedImage === image.value ? '50%' : '0'
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>

                                <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="mt-1"></div>
                                    <Button variant="default" disabled={selectedImage === null || selectedOption === null} className="mt-1 prev-btn-sme" onClick={() => assessmentData()}>Assess</Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/*completed assessmentPopup */}
                    <Modal show={showCompletedAssessmentPopup} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"

                        centered>
                        <Modal.Body style={{ background: "#EFF5FB", borderRadius: "16px" }}>
                            <div className="sme-booking-slot-popup-planned">
                                <div className="ml-1">  <img src={popupData.status === 1 ? tick : popupData.status === 2 ? cross : question} style={{ width: "34px", marginTop: "-3px" }} /></div>
                                <div style={{ fontSize: "18px", marginTop: "-4px", textAlign: "center" }}>Assesment of Session</div>
                                <div style={{ display: "flex", justifyContent: "space-between", color: "white", marginTop: "-4px", marginRight: "0.8rem", cursor: "pointer" }}> <div><i onClick={() => handleCloseCompletedAssessment()} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div></div>
                            </div>
                            <div className="sme-profile-bg-booking-req">
                                <Row style={{ width: "100%" }} className="no-gutters">
                                    <Col xs={3}>
                                        <center><img alt="" src={config.userUrl + popupData?.profilePicture} style={{ width: "50px" }} /></center>
                                        <center style={{ fontSize: "14px" }}>{popupData?.name}</center>
                                    </Col>
                                    <Col xs={9}>
                                        <center>
                                            <div style={{ marginTop: "-6px" }}>{getText(popupData.offeringId)} session</div>on {getExpertiseText(popupData?.topicId)}<br />
                                            ({moment(popupData.bookingDate).format("DD-MMM-YYYY")} @ {popupData.time})
                                        </center>
                                    </Col>
                                </Row>
                            </div>
                            {/* <Modal.Body>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-5px" }}> <div></div><i onClick={() => handleCloseCompletedAssessment()} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>

                                <center className="sme-slot-popup-heading" style={{ marginTop: "-1.3rem" }}>{getText(popupData.offeringId)} with {popupData.name}  <br />{moment(popupData.bookingDate).format("DD-MMM-YYYY")} @ {popupData.time}</center> */}
                            <Card style={{ border: "1px solid gray", borderRadius: "6px", padding: "4px" }}>
                                <Row style={{ width: "100%" }} className="no-gutters">
                                    <Col xs={8}>
                                        <div className="">Has the meeting happened?</div>
                                    </Col>
                                    <Col xs={2}>
                                        <div className={`mb-1 ml-2`}>
                                            <img src={tick}
                                                style={{
                                                    marginLeft: "4px",
                                                    marginTop: "-4px",
                                                    width: "30px",
                                                    border: popupData.meetingHappened === true ? '2px solid #ff942c' : 'none',
                                                    borderRadius: popupData.meetingHappened === true ? '50%' : '0'
                                                }} />
                                        </div>
                                    </Col>
                                    <Col xs={2}>
                                        <div className={`mb-1`}>
                                            <img src={cross} style={{
                                                marginTop: "-4px",
                                                width: "30px",
                                                border: popupData.meetingHappened === false ? '2px solid #ff942c' : 'none',
                                                borderRadius: popupData.meetingHappened === false ? '50%' : '0'
                                            }} />
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                            <div className="mt-2"></div>
                            <Card style={{ border: "1px solid gray", borderRadius: "6px", padding: "4px" }}>
                                <Row style={{ width: "100%" }} className="no-gutters">
                                    <Col xs={6}>
                                        <div className="mb-2 mt-1">Assessment</div>
                                    </Col>
                                    <Col xs={6}>
                                        <div style={{ display: "flex", gap: "2px", flexWrap: "wrap", marginTop: "-2px" }} className="ml-2">
                                            {images.map((image, index) => (
                                                <div className="mb-1">
                                                    <img
                                                        src={image.src}
                                                        style={{
                                                            width: "25px",
                                                            height: "25px",
                                                            border: popupData.assessment === image.value ? '2px solid #ff942c' : 'none',
                                                            borderRadius: popupData.assessment === image.value ? '50%' : '0'
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                            </Card>

                            {/* <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                <div className="mt-1"></div>
                                <Button variant="default" className="mt-1 prev-btn-sme" onClick={() => handleCloseCompletedAssessment()}>Close</Button>
                            </div> */}
                        </Modal.Body>
                    </Modal>
                    {/* event popup */}
                    <Modal show={showEventPopup} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        style={{ margin: 0 }}
                        centered>
                        <Modal.Body style={{ background: "#EFF5FB", borderRadius: "16px", padding: 0 }}>
                            <div className="sme-booking-slot-popup-planned">
                                <div className="ml-1"> </div>
                                <div style={{ fontSize: "18px", marginTop: "-4px", textAlign: "center" }}>{getText(popupData.offeringId)} Event</div>
                                <div style={{ display: "flex", justifyContent: "space-between", color: "white", marginTop: "-4px", marginRight: "0.8rem", cursor: "pointer" }}> <div></div><i onClick={() => setShowEventPopup(false)} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>
                            </div> <Row style={{ width: "100%", marginTop: "2rem", height: "6.2rem" }} className="no-gutters">
                                <Col xs={4}>
                                    <center><img alt="" src={config.userUrl + popupData.profilePicture} style={{ width: "58px", marginBottom: "5px" }} /></center>
                                    <center style={{ fontSize: "15px", lineHeight: "18px", fontFamily: "Noto Sans", fontWeight: 600 }} className="mt-2">{popupData?.name}</center>
                                </Col>
                                <Col xs={4}>
                                    <center>
                                        <div className="mt-2" style={{ lineHeight: "18px", fontSize: "15px", fontFamily: "Noto Sans", fontWeight: 600 }}>Registered</div>
                                        <div className="mt-2" style={{ lineHeight: "18px", fontSize: "15px", fontFamily: "Noto Sans", fontWeight: 600 }}>users</div>
                                        <div className="mt-3" style={{ lineHeight: "18px", fontSize: "25px", fontFamily: "Noto Sans", fontWeight: 600 }}>{popupData?.registeredcount}</div>
                                    </center>
                                </Col>
                                <Col xs={4}>
                                    <Card style={{ border: "none", marginRight: "15px", background: "#DCEAF7", borderRadius: "10px", fontWeight: 700, fontFamily: "Noto Sans" }}>
                                        <center>
                                            <div style={{ fontSize: "28px" }}> {moment(popupData.bookingDate).format("DD")}</div>
                                            <div style={{ fontSize: "16px", marginTop: "-4px" }}> {moment(popupData.bookingDate).format("MMM")}</div>
                                            <div style={{ fontSize: "16px", marginTop: "0px" }} className="mb-2"> {popupData.time}</div>
                                        </center>
                                    </Card>
                                </Col>
                            </Row>
                            <div className="mb-1"></div>
                            <div className="sme-booking-slot-popup-bottom">
                                <div style={{ fontSize: "12px", padding: "3px 8px", cursor: "pointer" }} onClick={() => openLinkUrl(popupData.link)}><img src={googlelinkImg} />{popupData.link}</div>
                                <div style={{ fontSize: "12px", padding: "3px 8px" }}><img src={linktext} /><i>{popupData.agenda}</i>
                                </div>
                                <div style={{ paddingBottom: "3px" }}></div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/*event assessmentPopup */}
                    <Modal show={showEventAssessmentPopup} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        centered>
                        <Modal.Body style={{ background: "#EFF5FB", borderRadius: "16px" }}>

                            <div className="sme-booking-slot-popup-planned">
                                <div className="ml-1">  <img src={popupData.status === 1 ? tick : popupData.status === 2 ? cross : question} style={{ width: "34px", marginTop: "-3px" }} /></div>
                                <div style={{ fontSize: "18px", marginTop: "-4px", textAlign: "center" }}>Assessment of Event</div>
                                <div style={{ display: "flex", justifyContent: "space-between", color: "white", marginTop: "-4px", marginRight: "0.8rem", cursor: "pointer" }}> <div></div><i onClick={() => handleCloseEventAssessment()} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>
                            </div>
                            <div className="sme-profile-bg-booking-req">
                                <Row style={{ width: "100%" }} className="no-gutters">
                                    <Col xs={3}>
                                        <center><img alt="" src={config.userUrl + popupData?.profilePicture} style={{ width: "50px" }} /></center>
                                        <center style={{ fontSize: "14px" }}>{popupData?.name}</center>
                                    </Col>
                                    <Col xs={9}>
                                        <center>
                                            <div style={{ marginTop: "-6px" }}>{getText(popupData.offeringId)} event</div>on <br />
                                            ({moment(popupData.bookingDate).format("DD-MMM-YYYY")} @ {popupData.time})
                                        </center>
                                    </Col>
                                </Row>
                            </div>
                            <div>
                                <Card style={{ border: "1px solid gray", borderRadius: "6px", padding: "4px" }}>
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col xs={9}>
                                            <div className="">Have you attended the event?</div>
                                        </Col>
                                        <Col xs={1}>
                                            <div className={`mb-1`} onClick={handleTickClick}>
                                                <img src={tick}
                                                    style={{
                                                        marginLeft: "4px",
                                                        marginTop: "-4px",
                                                        width: "30px",
                                                        border: selectedOption === true ? '2px solid #ff942c' : 'none',
                                                        borderRadius: selectedOption === true ? '50%' : '0'
                                                    }} />
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className={`mb-1`} style={{ marginLeft: "13px" }} onClick={handleCrossClick}>
                                                <img src={cross} style={{
                                                    marginTop: "-4px",
                                                    width: "30px",
                                                    border: selectedOption === false ? '2px solid #ff942c' : 'none',
                                                    borderRadius: selectedOption === false ? '50%' : '0'
                                                }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                                <div className="mt-2"></div>
                                <Card style={{ border: "1px solid gray", borderRadius: "6px", padding: "4px" }}>
                                    <Row style={{ width: "100%" }} className="no-gutters">
                                        <Col xs={6}>
                                            <div className="mb-2 mt-1">Assessment</div>
                                        </Col>
                                        <Col xs={6}>
                                            <div style={{ display: "flex", gap: "2px", flexWrap: "wrap", marginTop: "-2px", marginLeft: "5px" }} className="ml-2">
                                                {images.map((image, index) => (
                                                    <div className="mb-1" onClick={() => handleImageClick(image.value)}>
                                                        <img
                                                            src={image.src}
                                                            style={{
                                                                width: "25px",
                                                                height: "25px",
                                                                border: selectedImage === image.value ? '2px solid #ff942c' : 'none',
                                                                borderRadius: selectedImage === image.value ? '50%' : '0'
                                                            }}
                                                        />
                                                    </div>
                                                ))}
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>

                                <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="mt-1"></div>
                                    <Button variant="default" disabled={selectedImage === null || selectedOption === null} className="mt-1 prev-btn-sme" onClick={() => eventAssessmentData()}>Assess</Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal>
                    {/*event completed assessmentPopup */}
                    <Modal show={showCompletedEventAssessmentPopup} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"

                        centered>
                        <Modal.Body style={{ background: "#EFF5FB", borderRadius: "16px" }}>
                            <div className="sme-booking-slot-popup-planned">
                                <div className="ml-1">  <img src={popupData.status === 1 ? tick : popupData.status === 2 ? cross : question} style={{ width: "34px", marginTop: "-3px" }} /></div>
                                <div style={{ fontSize: "18px", marginTop: "-4px", textAlign: "center" }}>Assesment of Event</div>
                                <div style={{ display: "flex", justifyContent: "space-between", color: "white", marginTop: "-4px", marginRight: "0.8rem", cursor: "pointer" }}> <div><i onClick={() => handleCloseCompletedEventAssessment()} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div></div>
                            </div>
                            <div className="sme-profile-bg-booking-req">
                                <Row style={{ width: "100%" }} className="no-gutters">
                                    <Col xs={3}>
                                        <center><img alt="" src={config.userUrl + popupData?.profilePicture} style={{ width: "50px" }} /></center>
                                        <center style={{ fontSize: "14px" }}>{popupData?.name}</center>
                                    </Col>
                                    <Col xs={9}>
                                        <center>
                                            <div style={{ marginTop: "-6px" }}>{getText(popupData.offeringId)} event</div>on<br />
                                            ({moment(popupData.bookingDate).format("DD-MMM-YYYY")} @ {popupData.time})
                                        </center>
                                    </Col>
                                </Row>
                            </div>
                            {/* <Modal.Body>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-5px" }}> <div></div><i onClick={() => handleCloseCompletedAssessment()} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>

                                <center className="sme-slot-popup-heading" style={{ marginTop: "-1.3rem" }}>{getText(popupData.offeringId)} with {popupData.name}  <br />{moment(popupData.bookingDate).format("DD-MMM-YYYY")} @ {popupData.time}</center> */}
                            <Card style={{ border: "1px solid gray", borderRadius: "6px", padding: "4px" }}>
                                <Row style={{ width: "100%" }} className="no-gutters">
                                    <Col xs={9}>
                                        <div className="">Have you attended the event?</div>
                                    </Col>
                                    <Col xs={1}>
                                        <div className={`mb-1`}>
                                            <img src={tick}
                                                style={{
                                                    marginLeft: "4px",
                                                    marginTop: "-4px",
                                                    width: "30px",
                                                    border: popupData.meetingHappened === true ? '2px solid #ff942c' : 'none',
                                                    borderRadius: popupData.meetingHappened === true ? '50%' : '0'
                                                }} />
                                        </div>
                                    </Col>
                                    <Col xs={2}>
                                        <div className={`mb-1`} style={{ marginLeft: "13px" }}>
                                            <img src={cross} style={{
                                                marginTop: "-4px",
                                                width: "30px",
                                                border: popupData.meetingHappened === false ? '2px solid #ff942c' : 'none',
                                                borderRadius: popupData.meetingHappened === false ? '50%' : '0'
                                            }} />
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                            <div className="mt-2"></div>
                            <Card style={{ border: "1px solid gray", borderRadius: "6px", padding: "4px" }}>
                                <Row style={{ width: "100%" }} className="no-gutters">
                                    <Col xs={6}>
                                        <div className="mb-2 mt-1">Assessment</div>
                                    </Col>
                                    <Col xs={6}>
                                        <div style={{ display: "flex", gap: "2px", flexWrap: "wrap", marginTop: "-2px", marginLeft: "6px" }} className="ml-2">
                                            {images.map((image, index) => (
                                                <div className="mb-1">
                                                    <img
                                                        src={image.src}
                                                        style={{
                                                            width: "25px",
                                                            height: "25px",
                                                            border: popupData.assessment === image.value ? '2px solid #ff942c' : 'none',
                                                            borderRadius: popupData.assessment === image.value ? '50%' : '0'
                                                        }}
                                                    />
                                                </div>
                                            ))}
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Modal.Body>
                    </Modal>
                </div>

                {/* <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div></div>
                    <Button variant="default" className="mr-2 mt-1 prev-btn-sme" >Connect</Button>
                </div> */}
            </div>
            <div className="mb-bottom"></div>
            <BottomMenu />
        </div >
    )
}
const CustomInput = ({ value, onClick }) => (
    <button className="btn" onClick={onClick}>
        <i class="fa fa-calendar" id="true" aria-hidden="true"></i>
        <span className="_picker">        {value}</span>
    </button>
);