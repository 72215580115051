import React, { Component } from "react";
import { Card, Button, Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import flash from '../images/flash-on-svgrepo-com.svg';
import nodata from '../images/no-data.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import '../App.css';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class FavBits extends Component {
    constructor(props) {
        super(props);
        this.state = {
            bookmarkedPosts: [],
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            isLoading: true,
            userPosts: [],
            emailText: ""
        }
        this.goBack = this.goBack.bind(this)
    }
    goBack = () => {
        window.history.back();
    }
    componentDidMount() {
        this.getBookmarkedPosts();
        this.getUserPosts();
    }
    getBookmarkedPosts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getUserBookmarkedPosts', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    self.setState({ bookmarkedPosts:response.data.posts,isLoading: false })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    showNone() {
        document.getElementById('favbits').style.display = 'none';
    }
    showFavbits() {
        document.getElementById('favbits').style.display = 'flex';
    }
    getUserPosts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        var localPosts = localStorage.getItem('posts')
        var postData = JSON.parse(localPosts)
        const exp = new Date()
        if ((localPosts === null) || (exp.getTime() > postData.expiry)) {
            axios.get(config.userUrl + 'user/getPosts', { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                        self.setState({ userPosts: response.data.posts, isUserPostsLoading: false })
                        var item = {
                            "latestPosts": JSON.stringify(response.data.posts),
                            "expiry": exp.getTime() + 60 * 60 * 100
                        }
                        localStorage.setItem("posts", JSON.stringify(item))
                    } else {
                        console.log(response.data)
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        } else {
            this.setState({
                userPosts: JSON.parse(postData.latestPosts)
            })
        }
    };
    getUsersFavouritePosts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile",
            "responseType": "arraybuffer"
        }
        var self = this
        axios.get(config.userUrl + 'user/getUsersFavoritePosts', { headers: headers })
            .then(function (response) {
                //console.log("downloaded", response)
                if (response.data.status === true) {
                    //console.log("downloaded")
                    self.showFavbits();
                    self.setState({
                        emailText: "Email sent successfully!! Please check your email."
                    })
                } else {
                    //  console.log("error")
                    self.showFavbits();
                    self.setState({
                        emailText: "Sorry! There is some issue. Please try again after some time"
                    })
                }
            })
            .catch(function (error) {
                console.log("Error", error);
                self.showFavbits();
                self.setState({
                    emailText: "Sorry! There is some issue. Please try again after some time"
                })
            });
    };
    render() {
        const { isLoading, bookmarkedPosts, userPosts } = this.state;
        if (isLoading) {
            //  console.debug("renders: Loading...");
            return <div className="App"></div>;
        }
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>
                <div className="screen-topgap">
                    <div className="your-jitbits">Your Favourites
                        <span className="download-pdf" onClick={this.getUsersFavouritePosts}><i class="fa fa-download fa-lg" aria-hidden="true"></i></span>
                    </div>
                    <div className="ml-3 mr-3" >
                        {bookmarkedPosts.length !== 0 ?
                            <div className="fav-overflow">
                                {bookmarkedPosts.map((data, i) => (
                                    <Link to={{ pathname: `/article/${data.title.replace(/ /g, "_")}/${data.id}` }} className="link">
                                        <Card style={{ display: "flex" }} className="favbits-card">
                                            <Row style={{ width: "100%" }} className="no-gutters">
                                                <Col xs={2}>
                                                    <img alt="" src={config.userUrl + data.authorProfilePicture} className="ml-2 favbits-author" />
                                                </Col>
                                                <Col xs={10}>
                                                    {/* {data.title !== null && data.title > data.title.substring(0, 52) ?
                                                        <div className="ml-1 favbits-title">{data.title.substring(0, 52)}...</div> :
                                                        <div className="ml-1 favbits-title">{data.title}</div>} */}
                                                    <div className="ml-1 titleText-favbits mr-1">{data.title}</div>
                                                </Col>

                                            </Row>
                                        </Card>
                                    </Link>
                                ))}
                            </div> :
                            //  <center className="fav-overflow"><br/>No Bookmarks</center>
                            <div className="fav-overflow">
                                <img src={nodata} style={{ marginTop:"2%",width: "96%", marginLeft: "2%", marginRight: "2%" }} />
                                <center className="mt-3">No Bookmarks</center>
                            </div>
                        }

                        <div id="favbits" className="modal-pop" >
                            <form className="modal-content-log">
                                <div className="_container">
                                    <p className="mt-2">{this.state.emailText}</p>
                                    <center><Button variant="default" onClick={this.showNone} className="deletebutton">Ok</Button></center>
                                </div>
                            </form>
                        </div>
                        <center> <div><img src={flash} style={{ width: "30px" }} /><span className="read-also">Read also</span></div></center>
                        <div className="latest-art">Latest Articles</div>
                        {userPosts?.length !== 0 ?
                            <div className="latest-fav-overflow">
                                {userPosts.slice(0, 2).map((data, i) => (
                                    <Link to={{ pathname: `/article/${data.title.replace(/ /g, "_")}/${data.id}` }} className="link">
                                        <Card style={{ display: "flex", alignItems: "center" }} className="mt-2 favbits-art-card-art">
                                            <Row style={{ width: "100%" }} className="no-gutters">
                                                <Col xs={2} style={{ display: "flex", alignItems: "center" }}>
                                                    <img alt="" src={config.userUrl + data.authorProfilePicture} className="ml-2 favbits-author-art" />
                                                </Col>
                                                <Col xs={10} style={{ display: "flex", alignItems: "center" }}>
                                                    {/* {data.title !== null && data.title > data.title.substring(0, 100) ?
                                                        <div className="ml-1 favbits-title">{data.title.substring(0, 100)}...</div> :
                                                        <div className="ml-1 favbits-title">{data.title}</div>} */}
                                                    <div className="ml-1 titleText-favbits mr-1">{data.title}</div>
                                                </Col>

                                            </Row>
                                        </Card>
                                    </Link>
                                ))}
                            </div> : <center className="mt-5">No Latest Posts</center>}

                    </div>
                </div>
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div >
        )
    }
}
