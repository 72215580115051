import React, { Component } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import nodata from '../images/no-data.png';
import { config } from '../Config/Config';
import back from '../images/backarrow.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default class RedeemPoints extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offers: [],
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            isLoading: true,
            offerId: ""
        }
        this.goBack = this.goBack.bind(this)
    }
    goBack = () => {
        window.history.back();
    }

    showRedeem = () => {
        document.getElementById('redeem').style.display = 'flex';
    }
    hideRedeem = () => {
        document.getElementById('redeem').style.display = 'none';
        window.location.replace('/home')
    }
    showFailedRedeem = () => {
        document.getElementById('failed').style.display = 'flex';
    }
    hideFailedRedeem = () => {
        document.getElementById('failed').style.display = 'none';
    }
    componentDidMount() {
        this.getAllPublishedOffers();
    }
    getAllPublishedOffers = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAllPublishedOffer', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    self.setState({ offers: response.data.offer, isLoading: false })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    purchaseOffer = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "offerId": this.state.offerId,
        }
        var self = this;
        //  console.log("p", payload)
        axios.post(config.userUrl + 'user/purchaseOffer', payload, { headers: headers })
            .then((response) => {
                if (response.data.status == true) {
                    self.showRedeem()
                } else {
                    self.showFailedRedeem()
                }
            }).catch((error) => {
                console.log(error, "error")
                self.showFailedRedeem()
            });
    };
    render() {
        const { offers, isLoading } = this.state;

        if (isLoading) {
            //   console.debug("renders: Loading...");
            return <div className="App"></div>;
        }
        return (
            <div >
                <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                <div className="screen-topgap">
                    {/* <div className="your-jitbits">Offers Here</div> */}
                    <div className="mb-1"><Link to="/leaderboard"><img alt="" src={back} className="claims-back_arrow" /></Link><span style={{ marginLeft: "18%", fontFamily: "Noto Sans", fontSize: "16px", fontWeight: 600, color: "#ff942c" }}>Offers</span></div>

                    <div className="offer-overflow">
                        <div className="ml-2 mr-3 top-jit">
                            {this.state.offers.length !== 0 ?
                                <div>
                                    {this.state.offers.map((data, i) => (
                                        <span className="ml-2 mr-2 link" >
                                            <Card style={{ display: "flex" }} className="offers_Card" key={i} onClick={() => this.setState({ offerId: data.id })}>
                                                <Row style={{ width: "100%" }} className="no-gutters">
                                                    <Col xs={4}>
                                                        <img src={"https://res.cloudinary.com/sceem/image/upload/v1680525651/assets_tiqu38.png"} className="ml-2 mt-1 favbits-article-jit" />
                                                    </Col>
                                                    <Col xs={8}>
                                                        <div className="ml-2 favbits-title-new">{data.offerName}</div>
                                                        <div className="ml-2 favbits-title-new">{data.rewardPoints}</div>
                                                        <div className="ml-2 favbits-title-new">{data.url}</div>
                                                    </Col>
                                                </Row>
                                            </Card>
                                        </span>
                                    ))}
                                </div> :
                                <div>
                                    <img src={nodata} style={{ marginTop: "20%", width: "96%", marginLeft: "2%", marginRight: "2%" }} />
                                    <center className="mt-4">No offers</center>
                                </div>
                            }
                        </div>
                    </div>
                    {this.state.offerId !== "" ?
                        <Button variant="default" className="redeem-b" onClick={this.purchaseOffer} >Redeem</Button>
                        : <Button variant="default" className="redeem-b" disabled="true">Redeem</Button>
                    }
                </div>

                <div id="redeem" className="modal-pop">
                    <form className="modal-content-log">
                        <div className="_container">
                            <p className="mt-2" style={{ color: "#31197C", fontSize: "18px" }}>Reward Points Redeemed Successfully</p>
                            <center><Button variant="default" onClick={this.hideRedeem} className="deletebutton">Ok</Button></center>
                        </div>
                    </form>
                </div>
                <div id="failed" className="modal-pop">
                    <form className="modal-content-log">
                        <div className="_container">
                            <p className="mt-2" style={{ color: "#31197C", fontSize: "18px" }}>Redemption Failed</p>
                            <center><Button variant="default" onClick={this.hideFailedRedeem} className="deletebutton">Ok</Button></center>
                        </div>
                    </form>
                </div>
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div >
        )
    }
}