import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import picture from '../images/author4.png';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import '../JitBits/JitBits.css';
import nodata from '../images/no-data.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


export default class RegisteredUsers extends Component {
    constructor(props) {
        super(props);
        this.state = {
            users: [],
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            isLoading: true
        }
        this.goBack = this.goBack.bind(this)
    }
    goBack = () => {
        window.history.back();
    }
    componentDidMount() {
        this.getRegisteredUsers();
    }
    getRegisteredUsers = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getLatestRegisteredUsersData', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    // console.log(response.data.filtered)
                    self.setState({ users: response.data.filtered, isLoading: false })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { posts, isLoading } = this.state;

        if (isLoading) {
            //  console.debug("renders: Loading...");
            return <div className="App"></div>;
        }
        return (
            <div >
                <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                <div className="screen-topgap">
                    <div className="your-jitbits">Welcome to {(this.state.users.length)} new member(s)</div>
                    <div className="ml-3 mr-3 top-jit" >
                        {this.state.users.length !== 0 ?
                            <div>

                                {this.state.users.map((data, i) => (
                                    <a className="ml-2 mr-2 link">
                                        <Card style={{ display: "flex" }} className="jitbits--card">
                                            <Row style={{ width: "100%" }} className="no-gutters">
                                                <Col xs={4}>
                                                    <center>
                                                        <img alt="" src={data.profilePicture == null ? picture : config.userUrl + data.profilePicture} className="newly-registered" />
                                                        <div className="register-name" style={{ textAlign: "center" }}>{data.userName}</div>
                                                        <div style={{ fontSize: "11.5px", textAlign: "center" }}>{data.country}</div>
                                                    </center>
                                                </Col>
                                                <Col xs={8}>
                                                    <div className="mr-2">
                                                        <div className="ml-1 user-desc-title-new"><span><b style={{ color: "#FF942C" }}>{data.userName}&nbsp;</b><br />(<span className="registeredUser-plan">{data.planName}, {data.roleType}</span>)</span><br />
                                                            <div className="registered-users-overflow">
                                                                <div className="registeredUser-interest">is interested in {data.description}</div>
                                                                {data.topicDetails.length !== 0 ?
                                                                    <span style={{ fontSize: "12px" }}>Topics of Interests are
                                                                        {data.topicDetails.map((item, id) => {
                                                                            return (
                                                                                <i style={{ fontSize: "12px" }}> {item.topicName.replace(/ *\([^]*\) */g, "")}, </i>
                                                                            )
                                                                        })}
                                                                    </span>
                                                                    : ""}
                                                                {data.name !== null ?
                                                                    <b className="registeredUser-referred">Referred by {data.name == null ? "No one" : data.name}</b> :
                                                                    <b></b>}
                                                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                                                    <div></div>
                                                                    <b className="registeredUser-role">Joined on {data.createdAt.split("T")[0].split("-").reverse().join("-")}</b>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Card>
                                    </a>
                                ))}
                            </div> :
                            // <center className="mt-5">No Users registered since last 30 days </center>
                            <div>
                                <img src={nodata} style={{ marginTop: "12vh", width: "96%", marginLeft: "2%", marginRight: "2%" }} />
                                <center className="mt-4">No Users registered since last 30 days</center>
                            </div>
                        }
                    </div>
                </div>
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div >
        )
    }
}