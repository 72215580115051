import React, { useState, useEffect } from "react";
import './Interests.css';
import { useHistory, Link } from 'react-router-dom';
import BottomMenu from "../BottomMenu/BottomMenu";
import { AiOutlineShareAlt } from "react-icons/ai";
import { Redirect } from 'react-router-dom';
import polls from '../images/polls-new.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import { RiAlertFill, RiAlertLine } from "react-icons/ri";
import Report from "./Report"
import { OverlayTrigger, Tooltip, Row, Col, Button, Card, Popover, Form, Modal } from "react-bootstrap";
import { Helmet } from "react-helmet";
import flash from '../images/flash-on-svgrepo-com.svg';
import SimpleCrypto from "simple-crypto-js";


const key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY;
const simpleCrypto = new SimpleCrypto(key);

export default function ArticleDescription(props) {
    const history = useHistory();
    const [bookmark, setBookmark] = useState(false);
    const [like, setLike] = useState(false);
    const [share, setShare] = useState(false);
    const [postId, setPostId] = useState(props.match.params.id);
    const [token, setToken] = useState(localStorage.getItem('junkStore'));
    const [articleData, setArticleData] = useState([]);
    const [topicName, setTopicName] = useState("");
    const [authorUuid, setAuthorUuid] = useState("");
    const [authorName, setAuthorName] = useState("");
    const [authorImage, setAuthorImage] = useState("");
    const [description, setDescription] = useState("");
    const [typeOfReport, setTypeOfReport] = useState("");
    const [userViews, setUserViews] = useState("");
    const [show, setShow] = useState(false);
    const [campaign, setCampaign] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reportFlag, setReportFlag] = useState("");
    const [follow, setFollow] = useState(false);
    const [postCount, setPostCount] = useState("");
    const [isFirstTime, setIsFirstTime] = useState(false);
    const [scrolled, setScrolled] = useState(0);
    const [scroll, setScroll] = useState(0);
    const [submitOption, setSubmitOption] = useState(false);
    const [options, setOptions] = useState([]);
    const [postOptions, setPostOptions] = useState([]);
    const [question, setQuestion] = useState("");
    const [userAnswer, setUserAnswer] = useState(null);
    const [navWidth, setNavWidth] = useState(0);
    const [disabled, setDisabled] = useState(false);
    const [pollQuestion, setPollQuestion] = useState('');
    const [displayPopup, setDisplayPopup] = useState(false);
    const [appInstalled, setAppInstalled] = useState(true);
    const [deferredPrompt, setDeferredPrompt] = useState(null);
    const [authenticated, setAuthenticated] = useState(false);
    const [showQuestionPopup, setShowQuestionPopup] = useState(false);
    const [userPosts, setUserPosts] = useState([]);
    const [newArticle, setNewArticle] = useState(false);
    const [newId, setNewId] = useState();
    const [newTitle, setNewTitle] = useState();
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };
    useEffect(() => {
        const timer = setTimeout(() => {
            setShowQuestionPopup(!showQuestionPopup)
        }, 1000);

        return () => clearTimeout(timer);
    }, []);
    const getUserPosts = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var localPosts = localStorage.getItem('posts')
        var postData = JSON.parse(localPosts)
        const exp = new Date()
        if ((localPosts === null) || (exp.getTime() > postData.expiry)) {
            axios.get(config.userUrl + 'user/getPosts', { headers: headers })
                .then(function (response) {
                    if (response.data.status === true) {
                        setUserPosts(response.data.posts)
                        var item = {
                            "latestPosts": JSON.stringify(response.data.posts),
                            "expiry": exp.getTime() + 60 * 60 * 100
                        }
                        localStorage.setItem("posts", JSON.stringify(item))
                    } else {
                        console.log(response.data)
                    }
                })
                .catch(function (error) {
                    console.log("Error", error);
                });
        } else {
            setUserPosts(JSON.parse(postData.latestPosts))
        }
    };
    const closePopup = () => {
        setShowQuestionPopup(!showQuestionPopup)
    }
    // useEffect(() => {
    //     const openDeepLink = () => {
    //         if (!authenticated) {
    //             console.log("login");
    //             window.location.href = '/';
    //         } else {
    //             console.log("isUserLoggedIn");
    //             postLatestNews();
    //             getPostQuestionResultById();
    //         }
    //     };

    //     openDeepLink();
    //     window.addEventListener("scroll", onScroll);

    //     return () => {
    //         window.removeEventListener("scroll", onScroll);
    //     };
    // }, []);
    useEffect(() => {
        if (token) {
            try {
                setToken(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
                setAuthenticated(true);
                postLatestNews();
                getUserPosts()
                getPostQuestionResultById();
                window.addEventListener("scroll", onScroll);
                // console.error('token');
            } catch (error) {
                // If decryption fails or validation is unsuccessful, treat it as unauthenticated
                // console.error('Token decryption or validation failed:', error);
                setAuthenticated(false);
            }
        } else {
            // If token is not present, treat it as unauthenticated
            setAuthenticated(false);
            // console.error('else no token', authenticated);
            window.location.replace('/login')
        }
    }, [token]);
    // useEffect(() => {
    //     // Redirect to login screen if not authenticated
    //     if (!authenticated) {
    //       console.log('Redirecting to login');
    //       window.location.replace('/')
    //     }
    //   }, []);


    const onScroll = () => {
        const Scrolled = document.documentElement.scrollTop;
        const MaxHeight = document.documentElement.scrollHeight - document.documentElement.clientHeight;
        const ScrollPercent = (Scrolled / MaxHeight) * 100;
        setScroll(ScrollPercent);
    };

    const shareArticle = (title) => {
        if (navigator.share) {
            navigator.share({
                url: `https://www.sceem.org/${title.replace(/ /g, "_")}/${postId}`,
            }).then(() => {
                //console.log('Thanks for sharing!', title);
            }).catch(err => {
                console.log("Error while using Web share API:", err);
                //  console.log(err);
            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
    };

    const postLatestNews = () => {
        const headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        axios.get(config.userUrl + `user/getPostById/${props.match.params.id}`, { headers: headers })
            .then(response => {
                //  console.log(response);
                if (response.data.status === true) {
                    //   console.log("success!!");
                    setArticleData(response.data.post);
                    setBookmark(response.data.bookmarkedPostFlag);
                    setReportFlag(response.data.reportFlag);
                    setLike(response.data.likedPostFlag);
                    setTopicName(response.data.topicDetails.topicName);
                    setFollow(response.data.follow);
                    setPostCount(response.data.postCount);
                    setAuthorUuid(response.data.authorDetails.uuid);
                    setAuthorName(response.data.authorDetails.name);
                    setDescription(response.data.authorDetails.description);
                    setAuthorImage(response.data.authorDetails.profilePicture);
                    setUserViews(response.data.userViews);
                    setQuestion(response.data.post[0]?.question);
                    setOptions(response.data.postQuestionData);
                    setIsLoading(false);
                    setCampaign(response.data?.campaign)
                } else {
                    console.log(response.data);
                }
            })
            .catch(error => {
                console.log("Error");
            });
    };

    const postLikes = () => {
        setLike(!like);

        const headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        const payload = {
            postId: postId,
            likePost: !like,
            userIdPostBelongTo: articleData[0].userId
        };

        axios.post(config.userUrl + 'user/likePostToggle', payload, { headers: headers })
            .then(response => {
                //console.log("Likes", payload, response.data);
            })
            .catch(error => {
                console.log(error, payload);
            });
    };

    const followAuthor = () => {
        setFollow(!follow);
    };

    const postBookmark = () => {
        setBookmark(!bookmark);

        const headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        const payload = {
            postId: postId,
            bookmarkPost: !bookmark
        };

        axios.post(config.userUrl + 'user/bookmarkPostToggle', payload, { headers: headers })
            .then(response => {
                //  console.log("bookmark", payload, response.data);
                if (response.data.status === true) {
                    //  console.log("success!!");
                }
            })
            .catch(error => {
                console.log(error, payload);
            });
    };
    const parseMarkdown = (markdownText) => {
        var newMarkdownText = markdownText
            .replace(/^#(.*$)/gim, `<h5 class="heading-color"><b>$1</b></h5>`)
            .replace(/^>(.*$)/gim, '<li>$1</li>')
            .replace(/\_(.+?)\_/gim, '<u>$1</u>')
            .replace(/\%(.+?)\%/gim, '<i>$1</i>')
            .replace(/\*(.+?)\*/gim, '<b>$1</b>')
            .replace(/\n*$/gim, '<p />');
        return newMarkdownText;
    };

    // Event handler for input changes
    const onChange = (e) => {
        setQuestion({ ...question, [e.target.name]: e.target.value });
    };

    // Create Report function
    const createReport = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        let payload = {
            "articleId": postId,
            "typeOfReport": typeOfReport
        };

        // console.log("payload", token, payload);

        axios.post(config.userUrl + "user/saveArticleReport", payload, { headers: headers })
            .then((response) => {
                //console.log(response);
                if (response.data.status === true) {
                    //  console.log("Report created successfully");
                    //  console.log("payload", payload);
                    //   console.log(response.data);
                    setShow(false);
                    postLatestNews();
                } else {
                    console.log("Failed to create Report");
                }
            })
            .catch(function (error) {
                console.log("Failed to add report", payload, error);
            });
    };

    // Follow Authors function
    const followAuthors = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        let payload = {
            "authorIds": [authorUuid],
            "isFirstTime": isFirstTime
        };

        axios.post(config.userUrl + 'user/followAuthors', payload, { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    // console.log("success", payload, response.data);
                    localStorage.removeItem("getUserFollowedAuthors");
                    postLatestNews();
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };

    // Unfollow Authors function
    const unfollowAuthors = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        let payload = {
            "authorIds": [authorUuid],
            "isFirstTime": isFirstTime
        };

        axios.post(config.userUrl + 'user/unfollowAuthors', payload, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    //console.log("success", payload, response);
                    localStorage.removeItem("getUserFollowedAuthors");
                    postLatestNews();
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    // Show Popup function
    const showPopup = () => {
        setShow(!show);
    };

    // Hide Popover function
    const hidePopover = () => {
        document.getElementById("test").style.display = "none";
    };

    // Open Sidebar function
    const openSideBar = () => {
        setNavWidth('200px');
    };

    // Close Sidebar function
    const closeSideBar = () => {
        setNavWidth(0);
    };

    // Close Menu function
    const closeMenu = (e) => {
        if (e.target.tagName === "div") {
            document.getElementById("myNav").style.width = "0";
        }
    };

    // Check Answer function
    const checkAnswer = (answer) => {
        setUserAnswer(answer);
        setDisabled(false);
    };

    // Submit Post Question function
    const submitPostQuestion = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        let payload = {
            "postId": parseInt(postId),
            "postQuestionOptionId": parseInt(userAnswer)
        };

        //console.log("payload", payload);

        axios.post(config.userUrl + 'user/submitPostQuestion', payload, { headers: headers })
            .then(function (response) {
                //   console.log(response);
                if (response.data.status === true) {
                    setSubmitOption(true);
                    getPostQuestionResultById();
                    setShowQuestionPopup(!showQuestionPopup)
                    // console.log("success", response.data.message, payload);
                } else {
                    console.log("Error", payload);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            });
    };

    // Get Post Question Result By Id function
    const getPostQuestionResultById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        };

        axios.get(config.userUrl + `user/getPostQuestionResultById/${postId}`, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    /// console.log("success");
                    const result = response.data.postQuestionResult;
                    if (result.length === 0) {
                        setSubmitOption(false);
                    } else {
                        setPollQuestion(result.question);
                        setPostOptions(result.options);
                        setSubmitOption(true);
                    }
                } else {
                    console.log(response.data);
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    const redirectToLatestArticle = (id, title) => {
        setNewArticle(!newArticle)
        setNewId(id)
        setNewTitle(title)

        // window.history.push(`/article/${title.replace(/ /g, "_")}/${id}`)
    }
    useEffect(() => {
        if (newArticle) {
            history.push(`/article/${newTitle.replace(/ /g, "_")}/${newId}`);
        }
    }, [newArticle, history]);
    // if (newArticle) {
    //     return <Redirect
    //         to={`/article/${newTitle.replace(/ /g, "_")}/${newId}`} />
    // }
    const percent = postOptions?.map((opt, idx) => (
        Math.round((opt?.percentage + Number.EPSILON) * 100 / 100)
    ))
    // console.log("percentakdhs", percent)

    if (isLoading) {
        // console.debug("renders: Loading...");
        return <div className="App"></div>;
    }
    const b = articleData.map((i) => {
        // console.log("info", (i.publishOn))
        var a = new Date(i.publishOn)
        var publish = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        return publish;
    })
    if (displayPopup) {
        return <Report closePopup={showPopup} data={props} />
    }
    const contain = {
        background: "grey",
        height: "8px",
        position: "fixed",
        left: 0,
        zIndex: 1,
        width: "100%"
    };
    const progressBarStyle = {
        marginTop: "-0.2px",
        height: "8px",
        background: "#31197c",
        width: `${scroll}%`
    }
    const scrollContent = {
        // overflowY: "scroll"
    }

    // if (!authenticated) {
    //     window.location.replace('/')
    //     // return <Redirect to="/" />;
    //   }
    // const renderContent = () => {
    //     if (authenticated) {
    const extractHeadings = (htmlContent) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(htmlContent, 'text/html');
        const headings = doc.querySelectorAll('h1');
        return Array.from(headings).map(h1 => h1.textContent);
    };
    return (
        <div >
            <div style={scrollContent}>
                {articleData.map((data, id) => {
                    const headings = extractHeadings(data.content);
                    const modifiedContent = data.content.replace(/<h1>(.*?)<\/h1>/g, (match, p1) => {
                        const id = p1.replace(/\s+/g, '-').toLowerCase();
                        const className = !campaign ? 'article-data-h1' : 'article-h1';
                        return `<h1 class="${className}" id="${id}">${p1}</h1>`;
                    });
                    return (
                        <div>
                            <Helmet>
                                <meta data-react-helmet="true" property="og:title" content={data.title} />
                                <meta data-react-helmet="true" property="og:description"
                                    content={authorName} />
                            </Helmet>
                            <div>
                                {data.title !== null && data.title > data.title.substring(0, 83) ?

                                    <div className="tag-title">
                                        <div className="mt-3 mb-1 tagline-title" id="title">{data.title}
                                            <div style={{ position: "relative", top: "-2px" }}>
                                                <div style={contain}>
                                                    <div style={progressBarStyle}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    <div>
                                        <div className="tag-title">
                                            <div className="mt-3 mb-1 tagline-title" id="title">{data.title}
                                                <div style={{ position: "relative", top: "-2px" }}>
                                                    <div style={contain}>
                                                        <div style={progressBarStyle}>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div>
                                    <div>
                                        <div style={!campaign ? { display: "none" } : {}}>
                                            <div style={{ background: "white", position: "fixed", top: "13.5%", width: "96%", left: "2%", right: "2%", zIndex: 1000 }}>
                                                <Link to={`/advertisement/${campaign?.id}`} className="link" key={`ad-${campaign?.id}`} style={{ fontFamily: "Noto Sans", zIndex: 2000 }}>
                                                    <div style={{ marginLeft: "10px", marginTop: "7px", marginRight: "12px", border: "1px solid #DDDDDD", borderRadius: "10px", height: "4.3rem", background: "#F1FDF8", position: "relative", boxShadow: "2px 2px 4px #bebebe" }}>
                                                        <Row style={{ width: "100%" }} className="no-gutters">
                                                            <Col xs={9} style={{ borderRight: "1px solid #DDDDDD", height: "4.2rem", marginTop: "-4px", position: "relative" }}>
                                                                <div style={{ marginTop: "6px" }}>
                                                                    {campaign?.campaignName?.length > 75 ? (
                                                                        <div className="camp-name-home">{campaign?.campaignName.substring(0, 75)}...</div>
                                                                    ) : (
                                                                        <div className="camp-name-home">{campaign?.campaignName}</div>
                                                                    )}
                                                                    {campaign?.hasViewed ? (
                                                                        <div style={{ position: "absolute", left: "4px", bottom: "-2px", color: 'green' }}><i class="fa fa-circle" aria-hidden="true"></i></div>
                                                                    ) : (
                                                                        <div style={{ position: "absolute", left: "4px", bottom: "-2px", color: 'red' }}><i class="fa fa-circle" aria-hidden="true"></i></div>

                                                                    )}
                                                                </div>
                                                                <div className="camp-col">
                                                                    <i className="fa fa-certificate fa-stack-2x"></i>
                                                                    <span className="fa fa-stack-1x" style={{ color: "#31197C" }}>
                                                                        <span style={{ marginLeft: "6px", fontWeight: "600", fontFamily: "Open Sans", fontSize: "19px" }}>
                                                                            {campaign?.campaignPaymentType === "Paid" ? "P" : campaign?.campaignPaymentType === "Free" ? "F" : "D"}
                                                                        </span>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                            <Col xs={3} style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                                <img
                                                                    src={campaign?.imageUrl === "uploads/1649942289609advertisement.png" ? config.userUrl + campaign?.imageUrl : campaign?.imageUrl}
                                                                    className="adv-image-home"
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </Link>
                                            </div>
                                        </div>
                                        {/* <div className="stickytable"> */}
                                        <div style={!campaign ? { position: "sticky", top: "6.6rem", zIndex: 1000 } : { position: "sticky", top: "11.7rem", zIndex: 1000 }}>
                                            <div className="mt-2 table-of-contents-container">
                                                <div className="table-content">Table of Contents</div>
                                                <div>
                                                    <div onClick={toggleDropdown} style={{ border: "none" }}>
                                                        {isDropdownOpen ? <i class="fa fa-angle-up angle-size" aria-hidden="true"></i> : <i class="fa fa-angle-down angle-size" aria-hidden="true"></i>} {/* Toggle arrow */}
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                {isDropdownOpen && (
                                                    <div className="dd-content">
                                                        <ol>
                                                            {headings.map((heading, index) => {
                                                                const id = heading.replace(/\s+/g, '-').toLowerCase(); // Create the same ID
                                                                return (
                                                                    <li key={index} className="mt-1">
                                                                        <a
                                                                            href={`#${id}`}
                                                                            style={{ color: "black" }}
                                                                            onClick={(e) => {
                                                                                e.preventDefault(); // Prevent the default anchor behavior
                                                                                const element = document.getElementById(id);
                                                                                if (element) {
                                                                                    element.scrollIntoView({ behavior: 'smooth' });
                                                                                }
                                                                            }}
                                                                        >
                                                                            {heading}
                                                                        </a>
                                                                    </li>
                                                                );
                                                            })}
                                                            {data.question && (
                                                                <li className="mt-1">
                                                                    <a href="#article-poll" style={{ color: "black" }}>
                                                                        Article Poll
                                                                    </a>
                                                                </li>
                                                            )}
                                                        </ol>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                        <center className="image-des-top" style={!campaign ? { marginTop: "5.5rem" } : { marginTop: "10.5rem" }}><img alt="" src={config.userUrl + data.image} className="desc-Image" /></center>
                                        <div>
                                            <div className="icons-sm">
                                                <span onClick={postLikes}>
                                                    {like ?
                                                        <center><i class="fa fa-thumbs-up heart-icon mt-icon-top"></i></center> :
                                                        <center><i class="fa fa-thumbs-o-up heart-o mt-icon-top"></i></center>
                                                    }</span>
                                                <center>
                                                    <span onClick={() => shareArticle(data.title)} className="mt-icon-top"> <AiOutlineShareAlt size="18px" className="share-icon mt-icon-top-b" /></span>
                                                    </center>
                                                <span onClick={postBookmark}>
                                                    {bookmark ?
                                                        <center><i className="fa fa-bookmark bookmark-icon mt-icon-top" ></i></center> :
                                                        <center> <i className="fa fa-bookmark-o bookmark-o mt-icon-top"></i></center>}
                                                </span>
                                                <OverlayTrigger
                                                    placement="bottom"
                                                    trigger={reportFlag == 2 ?
                                                        ""
                                                        : (reportFlag == 3 ?
                                                            ""
                                                            : "click"
                                                        )}
                                                    rootClose
                                                    overlay={(
                                                        <Popover
                                                            id="test">
                                                            <Popover.Title as="h5">
                                                                Report
                                                            </Popover.Title>
                                                            <Popover.Content>
                                                                <Form.Check
                                                                    label="Unrelated"
                                                                    name="typeOfReport"
                                                                    type="radio"
                                                                    value={1}
                                                                    onChange={onChange}
                                                                />
                                                                <Form.Check
                                                                    label="Plagiarism"
                                                                    name="typeOfReport"
                                                                    type="radio"
                                                                    value={2}
                                                                    onChange={onChange}
                                                                />
                                                                <Form.Check
                                                                    label="Unformatted"
                                                                    name="typeOfReport"
                                                                    type="radio"
                                                                    value={3}
                                                                    onChange={onChange}
                                                                />
                                                                {typeOfReport !== "" ?
                                                                    <center className="mt-2"><Button variant="default" className="_rolebtn" onClick={createReport}>Submit</Button>&nbsp;&nbsp;<Button variant="default" onClick={hidePopover} className="_rolebtn" >Cancel</Button></center> :
                                                                    <center className="mt-2"><Button variant="default" disabled="true" className="_rolebtn" onClick={createReport}>Submit</Button>&nbsp;&nbsp;<Button variant="default" onClick={hidePopover} className="_rolebtn" >Cancel</Button></center>}
                                                            </Popover.Content>
                                                        </Popover>
                                                    )}>
                                                    {reportFlag == 2 ?
                                                        <OverlayTrigger placement="bottom" overlay={<Tooltip className="tooltip">Pending</Tooltip>}>
                                                            <center><RiAlertFill size="18px" className="mt-icon-top" style={{ color: "#31197C", marginLeft: "2px" }} /></center>
                                                        </OverlayTrigger>
                                                        : (reportFlag == 3 ?
                                                            <OverlayTrigger placement="bottom" overlay={<Tooltip className="tooltip">Rejected</Tooltip>}>
                                                                <center><RiAlertFill size="18px" className="mt-icon-top" style={{ color: "#FF942C", marginLeft: "2px" }} /></center>
                                                            </OverlayTrigger>
                                                            : <center><RiAlertLine size="18px" className="mt-icon-top" style={{ marginLeft: "2px" }} /></center>
                                                        )}
                                                </OverlayTrigger>
                                                <div className="mt-2"></div>
                                            </div>
                                        </div>
                                        <div style={{ wordWrap: "break-word" }} className="mt-3 html-texT" dangerouslySetInnerHTML={{ __html: modifiedContent }} />
                                        {data.question == "" ? <div style={data.question ? {} : { display: "none" }}></div> :
                                            <div style={data.question ? {} : { display: "none" }}>
                                                {submitOption ?
                                                    <div id="article-poll" className={!campaign ? 'no-art-d_' : 'art-d_'}>
                                                        <Col xs={12}>
                                                            <Card className="quiz-card-border">
                                                                <center className="art-poll-text-h"><img src={polls} style={{ width: "20px" }} />&nbsp;Article Poll</center>
                                                                <Card className="ml-2 mt-1 poll-result-card-post">
                                                                    <div style={{ padding: "5px" }}>
                                                                        <center className="poll-question-r"><b>{question}</b></center>
                                                                        <div>
                                                                            {postOptions.map((opt, idx) => (
                                                                                <Progressbar uAns={opt.isAnswer} answer={userAnswer} id={opt.id} hValue={percent} option={opt.option} percentage={percent[idx]} />
                                                                            ))}
                                                                        </div>
                                                                    </div>
                                                                </Card>
                                                            </Card>
                                                        </Col>
                                                    </div>
                                                    : <>
                                                        <Modal show={showQuestionPopup} size="lg"
                                                            aria-labelledby="contained-modal-title-vcenter"

                                                            centered>
                                                            <Modal.Body>
                                                                <Card className="quiz-card-border-p">
                                                                    <center className="art-poll-text-h"><img src={polls} style={{ width: "20px" }} />&nbsp;Article Poll</center>
                                                                    <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-25px" }}> <div></div><i onClick={closePopup} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>
                                                                    <Card className="ml-1 mt-3 poll-answer-card-post-p">
                                                                        <div className="poll-question-p"><b>{question}</b></div>
                                                                        {options?.map((option, idx) => {
                                                                            return (
                                                                                <div onClick={() => checkAnswer(option.id)} className={`polloptions-card-post ${userAnswer === option.id ? "selected" : null}`}><div className="poll-text">{option.option}</div></div>
                                                                            )
                                                                        })}
                                                                    </Card>
                                                                    {userAnswer === null ? <center><Button variant="default" disabled={true} className="mt-1 q-submit-btn" >&nbsp;Submit &nbsp;</Button></center>
                                                                        :
                                                                        <center><Button variant="default" className="mt-1 q-submit-btn" onClick={submitPostQuestion}>&nbsp;Submit &nbsp;</Button></center>}

                                                                </Card>
                                                            </Modal.Body>
                                                        </Modal>

                                                        <div id="article-poll" className={!campaign ? 'no-art-d_' : 'art-d_'}>
                                                            <Col xs={12}>
                                                                <Card className="quiz-card-border">
                                                                    <center className=" art-poll-text-h"><img src={polls} style={{ width: "20px" }} />&nbsp;Article Poll</center>
                                                                    <Card className="mt-1 ml-2 poll-answer-card-post">
                                                                        <div className="poll-question"><b>{question}</b></div>
                                                                        {options?.map((option, idx) => {
                                                                            return (
                                                                                <div onClick={() => checkAnswer(option.id)} className={`polloptions-card-post ${userAnswer === option.id ? "selected" : null}`}><div className="poll-text">{option.option}</div></div>
                                                                            )
                                                                        })}
                                                                    </Card>
                                                                    {userAnswer === null ? <center><Button variant="default" disabled={true} className="mt-2 mb-1 q-submit-btn" >&nbsp;Submit &nbsp;</Button></center>
                                                                        :
                                                                        <center><Button variant="default" className="mt-2 mb-1 q-submit-btn" onClick={submitPostQuestion}>&nbsp;Submit &nbsp;</Button></center>}
                                                                </Card>
                                                            </Col>
                                                        </div>

                                                    </>

                                                }
                                            </div>}

                                        <div style={{ marginTop: "0.5rem" }}>
                                            <hr style={{ height: "1px", backgroundColor: "lightgrey" }} />
                                            <Row style={{ width: "100%" }} className="no-gutters">
                                                <Col xs={2}>
                                                    <img alt="" src={authorImage === null ? "../author5.png" : config.userUrl + authorImage} className="Author_icon" />
                                                </Col>
                                                <Col xs={8} >
                                                    <div className="ml-3 mr-3" style={{ textAlign: "left", marginTop: "-4px" }}><span style={{ color: "#FF942D", fontSize: "18px", fontWeight: "700" }}>{authorName} </span><i> {description} <br /> </i><span style={{ fontSize: "10px" }}>{postCount == 1 ? <span>{postCount} article published.</span> : <span>{postCount} articles published.</span>}</span></div>
                                                </Col>
                                                <Col xs={2}>
                                                    {follow ? <div><div style={{ fontSize: "10px", marginLeft: "-8px" }}>UnFollow</div><i class="fa fa-minus-circle fa-lg plus" onClick={unfollowAuthors} aria-hidden="true"></i></div> : <div><div style={{ fontSize: "10px" }} className="ml-1">Follow</div><i class="fa fa-plus-circle fa-lg plus" onClick={followAuthors} aria-hidden="true"></i></div>}
                                                </Col>
                                            </Row>
                                            <hr style={{ height: "1px", backgroundColor: "lightgrey" }} />
                                            <div className="mr-ml">
                                                <div > <span id="topic" style={{ fontSize: "18px", marginLeft: "1.7rem", fontWeight: "600" }}>TOPIC:</span> {topicName.replace(/ *\([^]*\) */g, "")}</div>
                                                <div > <span style={{ fontSize: "18px", marginLeft: "1.7rem", fontWeight: "600" }}>PUBLISHED:</span> {b[id]}</div>
                                            </div>
                                            <div className="mr-ml"><span style={{ fontSize: "18px", marginLeft: "1.7rem", fontWeight: "600" }}>VIEWS:</span> {userViews}</div>

                                            <hr style={{ height: "1px", backgroundColor: "lightgrey" }} />
                                            {userPosts?.length !== 0 ?
                                                <div >
                                                    <center> <div className="mt-3"><img src={flash} style={{ width: "30px" }} /><span className="read-also">Read also</span></div></center>

                                                    {userPosts
                                                        .filter(post => parseInt(props.match.params.id) !== post.id) // Filter out items where id matches
                                                        .slice(0, userPosts.map(post => post.id).includes(parseInt(props.match.params.id)) ? 2 : 2) // Slice based on condition
                                                        .map((post, index) => (
                                                            <Card key={post.id} style={{ display: "flex" }} className="mt-2 latest-art-card" onClick={() => redirectToLatestArticle(post.id, post.title)}>
                                                                <Row style={{ width: "100%" }} className="no-gutters">
                                                                    <Col xs={2} style={{ display: "flex", alignItems: "center" }}>
                                                                        <img alt="" src={config.userUrl + post.authorProfilePicture} className="ml-2 favbits-author-art" />
                                                                    </Col>
                                                                    <Col xs={10} style={{ display: "flex", alignItems: "center" }}>
                                                                        {post.title !== null && post.title.length > 100 ?
                                                                            <div className="favbits-title-art">{post.title.substring(0, 100)}...</div> :
                                                                            <div className="favbits-title-art">{post.title}</div>}
                                                                    </Col>
                                                                </Row>
                                                            </Card>
                                                        ))}
                                                    <hr style={{ height: "7px", backgroundColor: "grey" }} />
                                                </div> : <center></center>}
                                        </div>
                                    </div>
                                </div>
                            </div>


                        </div>
                    )
                })
                }
            </div>
            <div className="mb-bottom"></div>
            <BottomMenu />
        </div >
    )
    //    }
    //     else {
    //         return <Redirect to="/" />;
    //     }
    // };
    // return (
    //     <div>
    //         {renderContent()}
    //     </div>
    //  );
}
const Progressbar = (props) => {
    const { percentage, option, uAns, selected, hValue, id, answer } = props;
    const backgroundColor = `linear-gradient(to right, #EEEBFF 0%, #EEEBFF ${percentage}%, white ${percentage}%, white 99%)`
    const containerStyles = {
        background: backgroundColor,
    }

    const isSelected = {
        border: "2px solid #DB6C00",
        borderRadius: 10,
        background: backgroundColor,
    }
    const isHighest = {
        border: "2px solid #31197C",
        borderRadius: 10,
        background: backgroundColor
    }
    const labelStyles = {
        color: 'black',
        borderColor: "#362B79",
        fontWeight: 500,
        fontFamily: "Noto Sans",
        fontSize: "14px",
        position: "relative",
        height: '6.5vh',
        marginTop: '1px',
        borderRadius: 8,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
    const optionLabels = {
        fontWeight: 600,
        margin: 'auto',
        height: '6.7vh',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    }
    const largestElement = (arr) => {
        return Math.max(...arr);
    }
    return (
        <div style={id === answer ? isSelected : largestElement(hValue) === percentage ? isHighest : containerStyles} className="container_class">
            <div style={labelStyles}>
                <div style={optionLabels} className="ml-1">{option}</div>

            </div>
            <div style={{ fontWeight: "600", marginRight: "5px" }}>{`${percentage}%`}</div>
        </div>

    );
};