import React from 'react';
import './App.css';
import Routes from './Routes';
import { BrowserRouter as Router } from 'react-router-dom';
import ScrollToTop from './ScrollToTop';
import axios from 'axios';
import { config } from './Config/Config';
import loginlogo from '../src/images/login-logo.png';
import nebutech from '../src/images/nebutech-logo.svg';
import { BrowserView, MobileView } from 'react-device-detect';
import SimpleCrypto from "simple-crypto-js";
import { Button, Navbar, Nav, Form, Container, Col, Row, Image } from "react-bootstrap";
import InstallButton from './Login/InstallButton';

var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY;
var simpleCrypto = new SimpleCrypto(key);

class App extends React.Component {
  state = {
    isDisconnected: false,
    onLoad: false,
    isMounted: false,
    email: "",
    isNewVersionAvailable: false
  };

  componentDidMount() {
    this.handleConnectionChange();
    window.addEventListener('online', this.handleConnectionChange);
    window.addEventListener('offline', this.handleConnectionChange);
    if (!this.getUserLoggedIn) {
      document.cookie = "userLoggedIn=true; expires = 0";
      window.addEventListener("beforeunload", this.logoutLocal);
    }

    // Disable right-click context menu globally
    window.addEventListener('contextmenu', this.handleContextMenu);
  }

  componentWillUnmount() {
    // Clean up the event listener
    window.removeEventListener('contextmenu', this.handleContextMenu);
  }

  handleContextMenu = (e) => {
    e.preventDefault(); // Disable context menu globally
  };

  // Allow context menu in a specific child component (e.g., InstallButton)
  allowContextMenu = (e) => {
    e.stopPropagation(); // Prevent the event from bubbling up
  };

  getUserLoggedIn = () => {
    var x = document.cookie;
    if (x.split('=')[0] === "userLoggedIn") {
      return x.split('=')[1];
    }
  };

  onChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  logoutLocal = (e) => {
    e.preventDefault();
    localStorage.clear();
    let headers = {
      "Content-Type": 'application/json',
      "Authorization": "Bearer " + simpleCrypto.decrypt(localStorage.getItem('junkStore')),
      "applicationType": "mobile"
    };
    axios.get(config.apiUrl + 'auth/logout', { headers: headers })
      .then((response) => {
        if (response.data.status === true) {
          localStorage.clear();
        }
      })
      .catch((error) => {
        // console.log("Error", error);
      });
  };

  handleConnectionChange = () => {
    const condition = navigator.onLine ? 'online' : 'offline';
    if (condition === 'online') {
      const webPing = setInterval(() => {
        fetch('//google.com', { mode: 'no-cors' })
          .then(() => {
            this.setState({ isDisconnected: false }, () => {
              return clearInterval(webPing);
            });
          }).catch(() => this.setState({ isDisconnected: true }));
      }, 2000);
      return;
    }

    return this.setState({ isDisconnected: true });
  };

  render() {
    const { isDisconnected } = this.state;
    const width = window.innerWidth;

    return (
      <div>
        {width < 500 ? (
          <div>
            <center id="turn">
              <h4 className="no-connection">Works on portrait mode, please rotate your device</h4>
            </center>
            {isDisconnected ? (
              <center className="no-connection"><h4>No Internet connection</h4></center>
            ) : (
              <div id="container">
                <InstallButton />
                <Router>
                  <ScrollToTop />
                  <Routes />
                </Router>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Container>
              <Row>
                <Col md={1}></Col>
                <Col md={5}>
                  <img src={loginlogo} style={{ marginTop: "45%" }} />
                </Col>
                <Col md={5} style={{ marginTop: "13%", marginLeft: "5%" }}>
                  <div className="sceem-app">
                    <h5><b>Sceem App</b></h5>
                    <div style={{
                      marginTop: "15px",
                      marginBottom: "15px",
                      border: "solid grey",
                      borderWidth: "0 0 4px 0px"
                    }} />
                  </div>
                  <div>Sceem is designed as a mobile-friendly application. It is available on all mobile devices with Chrome or Edge browsers. You do not have to install from any playstore.</div>
                  <p>
                    <div className="mt-5">
                      <div>
                        Just use the same link (<a href="https://mob.sceem.org/" className="link-color">https://mob.sceem.org/</a>) and enjoy its rich features.
                      </div>
                    </div>
                  </p>
                </Col>
                <Col md={1}></Col>
              </Row>
            </Container>
            <div className="nebutecH">
              <img src={nebutech} className="nebutecH-login-img" />
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default App;