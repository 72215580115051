import { enqueueSnackbar } from 'notistack';
import React, { useState } from 'react';
import { Form, Button, Container, Row, Col, InputGroup, FormControl } from 'react-bootstrap';
import loginlogo from '../images/login-logo.png';
import axios from 'axios';
import { Link, Redirect } from 'react-router-dom';
import { config } from '../Config/Config';
import BottomMenu from "../BottomMenu/BottomMenu";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const PlaceOrderForm = () => {
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [size, setSize] = useState('M');
    const [quantity, setQuantity] = useState(1);
    const [price, setPrice] = useState(599);
    const [address, setAddress] = useState('');
    const [color, setColor] = useState('White');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [redirect, setRedirect] = useState(false);

    const handleSizeChange = (event) => {
        setSize(event.target.value);
    };
    const handleColorChange = (event) => {
        setColor(event.target.value);
    };
    const handleQuantityChange = (event) => {
        const qty = parseInt(event.target.value);
        setQuantity(qty);
        setPrice(qty * 599);
    };

    const handleAddressChange = (event) => {
        setAddress(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
    };
    const handleSubmit = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "size": size,
            "quantity": quantity,
            "color":color,
            "price": price,
            "address": address,
            "phoneNumber": phoneNumber
        }
        console.log("payload", payload)
        axios.post(config.userUrl + 'user/placeTshirtOrder', payload, { headers: headers })
            .then(function (response) {
                console.log(response);
                if (response.data.status === true) {
                    enqueueSnackbar('Order Placed Successfully', { variant: "success" })
                    setRedirect(true);
                }
            })
            .catch(function (error) {
                console.log("error", error);
            })


    }
    if (redirect) {
        return <Redirect
            to={{
                pathname: `/purchases`
            }}
        />
    }
    return (
        <div >
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="screen-topgap">
                <div className="your-jitbits">Order Tshirt</div>
                <div style={{ fontFamily: "Noto Sans" }}>
                    <Form className="ml-4 mr-4">
                        <Row style={{ width: "100%" }} className='no-gutters'>
                            <Col>
                                <Form.Group controlId="sizeSelect">
                                    <Form.Label>Size</Form.Label>
                                    <Form.Control as="select" value={size} onChange={handleSizeChange}>
                                        <option>S</option>
                                        <option>M</option>
                                        <option>L</option>
                                        <option>XL</option>
                                        <option>XXL</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="colorSelect">
                                    <Form.Label>Color</Form.Label>
                                    <Form.Control as="select" value={color} onChange={handleColorChange}>
                                        <option>White</option>
                                        <option>Navy Blue</option>
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row style={{ width: "100%" }} className='no-gutters'>
                            <Col>
                                <Form.Group controlId="quantitySelect">
                                    <Form.Label>Quantity</Form.Label>
                                    <Form.Control as="select" className="mt-1" value={quantity} onChange={handleQuantityChange}>
                                        {[1, 2, 3, 4, 5].map((qty) => (
                                            <option key={qty} value={qty}>
                                                {qty}
                                            </option>
                                        ))}
                                    </Form.Control>
                                </Form.Group>
                            </Col>
                            <Col>
                                <Form.Group controlId="price">
                                    <Form.Label>Price</Form.Label>
                                    <InputGroup>
                                        <InputGroup.Text>Rs.</InputGroup.Text>
                                        <FormControl readOnly value={price} />
                                    </InputGroup>
                                </Form.Group>
                            </Col>
                        </Row>
                        <Form.Group controlId="phoneNumber">
                            <Form.Label>Phone Number</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Enter your phone number"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                            />
                        </Form.Group>
                        <Form.Group controlId="address">
                            <Form.Label>Address</Form.Label>
                            <textarea
                                rows={4}
                                style={{ resize: "none", width: "100%", padding: "10px", borderRadius: "4px" }}
                                placeholder="Enter your address"
                                value={address}
                                onChange={handleAddressChange}
                            />
                        </Form.Group>

                        <Button variant="default" className="mt-4 btn btn-block block-d" onClick={handleSubmit} style={{ background: "#31197C", color: "white", fontFamily: "Noto Sans" }}>Buy Now</Button>
                    </Form>
                </div>
            </div>
            <BottomMenu />
        </div>
    );
};

export default PlaceOrderForm;