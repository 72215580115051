import React, { useEffect, useState } from "react";
import { Button, Card, Col, Row, Modal } from "react-bootstrap";
import { Link, useLocation } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import { enqueueSnackbar } from 'notistack'
import article from '../images/article-new.jpeg';
import nodata from '../images/no-data.png';
import tick from '../images/tick.svg';
import cross from '../images/cross.svg';
import question from '../images/questionmark.svg';
import googlelinkImg from '../images/googlelinkblack.svg';
import assessmentdone from '../images/assesmentdone.svg';
import assessmentone from '../images/assessment1.svg';
import assessmenttwo from '../images/assessment2.svg';
import assessmentthree from '../images/assessment3.svg';
import assessmentfour from '../images/assessment4.svg';
import assessmentfive from '../images/assessment5.svg';
import linktext from '../images/linktextblack.svg';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)


const todayDate = moment(new Date(Date.now())).format("DD-MM-YYYY");
export default function SMERequests() {
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [currDate, setCurrDate] = useState()
    const [selectedId, setSelectedId] = useState()
    const [showPopup, setShowPopup] = useState(false)
    const [showAssessmentPopup, setShowAssessmentPopup] = useState(false)
    const [popupData, setPopupData] = useState([])
    const [allRequests, setAllRequests] = useState([])
    const [allOfferings, setAllOfferings] = useState([])
    const [requestsData, setRequestsData] = useState([])
    const [topics, setTopics] = useState([])
    const [selectedDate, setSelectedDate] = useState()
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedImage, setSelectedImage] = useState(null);
    const today = new Date();

    const months = [
        "Jan", "Feb", "Mar", "Apr", "May", "Jun",
        "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    const images = [
        { src: assessmentone, value: 1 },
        { src: assessmenttwo, value: 2 },
        { src: assessmentthree, value: 3 },
        { src: assessmentfour, value: 4 },
        { src: assessmentfive, value: 5 }
    ];
    useEffect(() => {
        const formattedDate = `${today.getDate()}-${months[today.getMonth()]}-${today.getFullYear()}`;
        setCurrDate(formattedDate)
        getAllSmeOfferings()
        getAllRegisterTopics()
        getSmeBookingRequests(formattedDate);
    }, [])
    const handleImageClick = (value) => {
        setSelectedImage(value);
    };

    const handleTickClick = () => {
        setSelectedOption(true);
    };

    const handleCrossClick = () => {
        setSelectedOption(false);
    };
    const getText = (offeringId) => {
        const item = allOfferings.find(txt => txt.id === offeringId);
        return item ? item.text : "";
    }
    const getExpertiseText = (id) => {
        const item = topics.find(txt => txt.id === id);
        return item ? item.topicName : "";
    }
    const handlePopup = (data) => {
        setShowPopup(true)
        setPopupData(data)
    }
    const handleShowAssessment = (data) => {
        setShowAssessmentPopup(true)
        setPopupData(data)
    }
    const handleCloseAssessment = () => {
        setShowAssessmentPopup(false)
        setSelectedImage(null)
        setSelectedOption(null)
    }
    const handleDateChange = date => {
        setSelectedDate(date);
        // console.log("date", date)
        const formattedDate = date ? formatDate(date) : null;
        // console.log(formattedDate);
        // setFormattedNewDate(formattedDate)
        getSmeBookingRequests(date)
    };
    const formatDate = date => {
        const options = { day: '2-digit', month: 'short', year: 'numeric' };
        return new Intl.DateTimeFormat('en-GB', options).format(date);
    };
    const timeToValue = (time) => {
        const [hour, minute] = time.split(/:| /);
        let value = parseInt(hour) * 60;
        if (time.includes("PM") && hour !== "12") {
            value += 12 * 60; // Convert to 24-hour format
        }
        value += parseInt(minute);
        return value;
    };
    const getSmeBookingRequests = (date) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "date": moment(date).format("YYYY-MM-DD")
        }
        axios.post(config.userUrl + 'user/getSmeBookingRequests', payload, { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    // setAllRequests(response.data.filteredData)
                    let data = response.data.filteredData?.sort((a, b) => {
                        const timeValueA = timeToValue(a.time);
                        const timeValueB = timeToValue(b.time);
                        return timeValueA - timeValueB;
                    });
                    setAllRequests(data)
                    //  console.log("success")
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setAllOfferings(response.data.offerings)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllRegisterTopics = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllRegisterTopics', { headers: headers })
            .then(function (response) {
                //   console.log(response);
                if (response.data.status === true) {
                    setTopics(response.data.topics)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    // const getAllDays = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     axios.get(config.userUrl + 'user/getAllDays', { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status === true) {
    //                 setAllDays(response.data.days)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // };
    // const getAllTimeSlots = () => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     axios.get(config.userUrl + 'user/getAllTimeSlots', { headers: headers })
    //         .then(function (response) {
    //             console.log(response);
    //             if (response.data.status === true) {
    //                 setAllTimeSlots(response.data.timeSlots)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // };
    const acceptData = (val) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "status": val,
            "id": parseInt(popupData?.id)
        }
        axios.post(config.userUrl + 'user/acceptorRejectSmeBooking', payload, { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    setShowPopup(false)
                    getSmeBookingRequests(selectedDate)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const assessmentData = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "id": parseInt(popupData?.id),
            "assessment": parseInt(selectedImage),
            "meetingHappened": selectedOption == true ? true : false
        }
        axios.post(config.userUrl + 'user/smeBookingAssessment', payload, { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    setShowAssessmentPopup(false)
                    setSelectedImage(null)
                    setSelectedOption(null)
                    window.location.reload()
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const openLinkUrl = (link) => {
        window.open(`${link}`)
    }
    const bookingDate = moment(popupData?.bookingDate); // Parse the ISO 8601 date format
    const isBookingDateValid = bookingDate.isValid();
    // const isAfterToday = isBookingDateValid && bookingDate.isAfter(moment(todayDate, "DD-MM-YYYY"));

    // console.log("Today Date:", todayDate);
    // console.log("Original Booking Date:", popupData.bookingDate);
    // console.log("Parsed Booking Date:", isBookingDateValid ? bookingDate.format("DD-MM-YYYY") : "Invalid Date");
    // console.log("Is Booking Date Valid?:", isBookingDateValid);
    // console.log("Is Booking Date after Today Date?:", isAfterToday);
    // console.log("Status:", popupData.status);
    // console.log("Condition:", popupData.status === 3 && isAfterToday);
    return (
        <div >
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="mt-5">
                <div className="your-jitbits" style={{ display: "flex" }}>SME Requests <div className="ml-1" style={{ marginTop: "-12px" }}>
                    <DatePicker
                        customInput={<CustomInput />}
                        selected={selectedDate ? selectedDate : new Date(Date.now())}
                        onChange={handleDateChange}
                        className="_picker"
                        style={{ border: "none" }}
                        dateFormat="dd-MMM-yyyy"
                        placeholder="Select Date"
                    /></div></div>
                <div className="ml-2 mr-2 mb-2 sme-planned-border">
                    {allRequests.length != 0 ?
                        <div className="mt-2 mb-3">
                            {allRequests.map((data, i) => (
                                <Card className="sme-planned-card sme-animation" style={moment(new Date(Date.now())).format("YYYY-MM-DD") === moment(data?.updatedAt).format("YYYY-MM-DD") && (data.status === 2 || data.status === 1) ? { border: "2px solid #FF942C", "--animation-order": i } : { border: "2px solid black", "--animation-order": i }}>
                                    <Row style={{ width: "100%" }} className="no-gutters" onClick={() => handlePopup(data)}>
                                        <Col xs={9}>
                                            <div className="favbits-title mb-1"><img src={getText(data.offeringId).replace(/ *\([^]*\) */g, "") + ".svg"} alt={getText(data.offeringId)} style={{ marginTop: "-6px" }} /> {data.time} {data.name} </div>
                                        </Col>
                                        <Col xs={2}>
                                            {/* <div className="mt-2 favbits-title">{data.amount === 0 ? <>Free</> : <>Rs.{data.amount}</>}</div> */}
                                            {data.amount === 0 ? <div className="mt-2 favbits-title">Free</div> :
                                                <>
                                                    {data.status === 1 && (data.meetingHappened === false || data.meetingHappened === null) && moment(data.bookingDate, "DD-MM-YYYY").isBefore(moment(todayDate, "DD-MM-YYYY")) ? <div style={{ color: "red" }} className="mt-2 favbits-title">Rs.{data.amount}</div> : ""}
                                                    {data.status === 1 && data.meetingHappened === true && moment(data.bookingDate, "DD-MM-YYYY").isBefore(moment(todayDate, "DD-MM-YYYY")) ? <div className="mt-2 favbits-title">Rs.{data.amount}</div> : ""}
                                                    {data.status === 2 && (data.meetingHappened === false || data.meetingHappened === null) && moment(data.bookingDate, "DD-MM-YYYY").isBefore(moment(todayDate, "DD-MM-YYYY")) ? <div style={{ color: "red" }} className="mt-2 favbits-title">Rs.{data.amount}</div> : ""}
                                                    {data.status === 3 && (data.meetingHappened === false || data.meetingHappened === null) && moment(data.bookingDate, "DD-MM-YYYY").isBefore(moment(todayDate, "DD-MM-YYYY")) ? <div style={{ color: "red" }} className="mt-2 favbits-title">Rs.{data.amount}</div> : ""}
                                                    {data.status === 3 && (data.meetingHappened === false || data.meetingHappened === null) && moment(data.bookingDate, "DD-MM-YYYY").isSameOrAfter(moment(todayDate, "DD-MM-YYYY")) ? (<div className="mt-2 favbits-title">Rs.{data.amount}</div>) : ""}
                                                </>
                                            }
                                        </Col>
                                        <Col xs={1}>
                                            <div>
                                                {data.status === 1 ? (
                                                    <span><img src={tick} style={{ marginTop: "-2px", width: "30px" }} /></span>
                                                ) : (data.status === 2) ? (<span><img src={cross} style={{ marginTop: "-2px", width: "30px" }} /></span>
                                                ) :
                                                    <span><img src={question} style={{ marginTop: "-2px", width: "30px" }} /></span>}
                                            </div>
                                            {/* <div>
                                                {moment(data.bookingDate).isBefore(moment(), 'day') ? (
                                                    data.assessment !== null ? (
                                                        <span className="ml-3"><img src={assessmentdone} style={{ marginTop: "-4px", width: "34px" }} /></span>
                                                    ) : (
                                                        <span className="ml-3"><img src={noassessment} style={{ marginTop: "-4px", width: "34px" }} /></span>
                                                    )
                                                ) : (
                                                    //  date is not completed
                                                    data.status === 1 ? (
                                                        <span className="ml-3"><img src={tick} style={{ marginTop: "-4px", width: "34px" }} /></span>
                                                    ) : data.status === 2 ? (
                                                        <span className="ml-3"><img src={cross} style={{ marginTop: "-4px", width: "34px" }} /></span>
                                                    ) : (
                                                        <span className="ml-3"><img src={question} style={{ marginTop: "-4px", width: "34px" }} /></span>
                                                    ))
                                                }
                                            </div> */}

                                        </Col>
                                    </Row>
                                </Card>))}
                        </div> :
                        // <div><center className="mt-5">No requests</center></div>
                        <div>
                            <img src={nodata} style={{ marginTop: "16%", width: "96%", marginLeft: "2%", marginRight: "2%" }} />
                            <center className="mt-4">No Requests.</center>
                        </div>
                    }
                    <Modal show={showPopup} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        style={{ margin: 0 }}
                        centered>
                        <Modal.Body style={{ background: "#EFF5FB", borderRadius: "16px", padding: 0 }}>
                            <div className="sme-booking-slot-popup">
                                <div className="ml-1">  <img src={popupData.status === 1 ? tick : popupData.status === 2 ? cross : question} style={{ width: "34px", marginTop: "-3px" }} /></div>
                                <div style={{ fontSize: "18px", textAlign: "center", marginTop: "-4px", }}>{popupData.status == 1 ? "Request Status" : "Confirm Request"}</div>
                                <div style={{ display: "flex", justifyContent: "space-between", color: "white", marginTop: "-4px", marginRight: "0.8rem", cursor: "pointer" }}> <div></div><i onClick={() => setShowPopup(false)} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>
                            </div>
                            <Row style={{ width: "100%", marginTop: "2rem", height: "6rem" }} className="no-gutters">
                                <Col xs={4}>
                                    <center><img alt="" src={config.userUrl + popupData?.profilePicture} style={{ width: "60px", marginBottom: "3px" }} /></center>
                                    <center style={{ fontSize: "15px", lineHeight: "18px", fontFamily: "Noto Sans", fontWeight: 600 }} className="mt-2">{popupData?.name}</center>
                                </Col>
                                <Col xs={4}>
                                    <center>
                                        <img src={getExpertiseText(popupData?.topicId) + ".png"} alt={popupData.topicId} style={{ width: "55px", marginTop: "3px", marginBottom: "5px" }} />
                                        <div className="mt-2" style={{ lineHeight: "18px", fontSize: "15px", fontFamily: "Noto Sans", fontWeight: 600 }}>{getExpertiseText(popupData?.topicId)}</div>
                                    </center>
                                </Col>
                                <Col xs={4}>
                                    <Card style={{ border: "none", marginRight: "5px", background: "#DCEAF7", borderRadius: "10px", fontWeight: 700, fontFamily: "Noto Sans" }}>
                                        <center>
                                            <div style={{ fontSize: "28px" }}> {moment(popupData.bookingDate).format("DD")}</div>
                                            <div style={{ fontSize: "16px", marginTop: "-4px" }}> {moment(popupData.bookingDate).format("MMM")}</div>
                                            <div style={{ fontSize: "16px", marginTop: "0px" }} className="mb-2"> {popupData.time}</div>
                                        </center>
                                    </Card>
                                </Col>
                            </Row>
                            <div className="sme-profile-bg-booking-req_" style={{ marginTop: "1rem", marginLeft: "6px", marginRight: "6px" }}>
                                <div style={{ fontSize: "12px", padding: "3px 8px", cursor: "pointer" }} onClick={() => openLinkUrl(popupData.link)}><img src={googlelinkImg} />&nbsp;{popupData.link}</div>
                                <div style={{ fontSize: "12px", padding: "3px 8px" }}><img src={linktext} /><i>{popupData.agenda}</i>
                                </div>
                            </div>

                            {/* //  {console.log("dta", popupData.status, popupData.bookingDate, moment(popupData.bookingDate, "DD-MM-YYYY").isBefore(moment(todayDate, "DD-MM-YYYY")))} */}

                            <div style={{ margin: "10px", fontSize: "12px", fontfamily: "Noto Sans" }}>
                                {popupData.amount > 0 && (
                                    // <> {popupData.status === 1 && (popupData.meetingHappened === false || popupData.meetingHappened === null) && moment(popupData?.bookingDate, "DD-MM-YYYY").isBefore(moment(todayDate, "DD-MM-YYYY")) ? <>Amount to be refunded to user: Rs.{popupData.amount}</> : null}
                                    //     {popupData.status === 1 && popupData.meetingHappened === true && moment(popupData?.bookingDate, "DD-MM-YYYY").isBefore(moment(todayDate, "DD-MM-YYYY")) ? <>Amount to be paid to SME: Rs.{popupData.amount}</> : ""}
                                    //     {popupData.status === 2 && (popupData.meetingHappened === false || popupData.meetingHappened === null) && moment(popupData?.bookingDate, "DD-MM-YYYY").isAfter(moment(todayDate, "DD-MM-YYYY")) ? <>Amount to be refunded to user: Rs.{popupData.amount}</> : null}
                                    //     {popupData.status === 3 && (popupData.meetingHappened === false || popupData.meetingHappened === null) && moment(popupData?.bookingDate, "DD-MM-YYYY").isAfter(moment(todayDate, "DD-MM-YYYY")) ? <>Amount to be refunded to user: Rs.{popupData.amount}</> : null}
                                    // </>

                                    // <>
                                    //     {popupData.status === 1 && (popupData.meetingHappened === false || popupData.meetingHappened === null) && moment(popupData?.bookingDate, "DD-MM-YYYY").valueOf() > moment(todayDate, "DD-MM-YYYY").valueOf() ? <>Amount to be refunded to user: Rs.{popupData.amount}</> : null}
                                    //     {popupData.status === 1 && popupData.meetingHappened === true && moment(popupData?.bookingDate, "DD-MM-YYYY").valueOf() < moment(todayDate, "DD-MM-YYYY").valueOf() ? <>Amount to be paid to SME: Rs.{popupData.amount}</> : ""}
                                    //     {popupData.status === 2 && (popupData.meetingHappened === false || popupData.meetingHappened === null) && moment(popupData?.bookingDate, "DD-MM-YYYY").valueOf() > moment(todayDate, "DD-MM-YYYY").valueOf() ? <>Amount to be refunded to user: Rs.{popupData.amount}</> : null}
                                    //     {popupData.status === 3 && (popupData.meetingHappened === false || popupData.meetingHappened === null) && moment(popupData?.bookingDate, "DD-MM-YYYY").valueOf() > moment(todayDate, "DD-MM-YYYY").valueOf() ? <>Amount to be refunded to user: Rs.{popupData.amount}</> : null}
                                    // </>
                                    <>
                                        {popupData.status === 1 && (popupData.meetingHappened === false || popupData.meetingHappened === null) && moment(popupData?.bookingDate).isBefore(moment(todayDate, "DD-MM-YYYY")) ? (
                                            <>Amount to be refunded to user: Rs.{popupData.amount}</>
                                        ) : null}
                                        {popupData.status === 1 && popupData.meetingHappened === true && moment(popupData?.bookingDate).isBefore(moment(todayDate, "DD-MM-YYYY")) ? (
                                            <>Amount to be paid to SME: Rs.{popupData.amount}</>
                                        ) : ""}
                                        {popupData.status === 2 && (popupData.meetingHappened === false || popupData.meetingHappened === null) && moment(popupData?.bookingDate).isBefore(moment(todayDate, "DD-MM-YYYY")) ? (
                                            <>Amount to be refunded to user: Rs.{popupData.amount}</>
                                        ) : null}
                                        {popupData.status === 3 && (popupData.meetingHappened === false || popupData.meetingHappened === null) && moment(popupData?.bookingDate).isBefore(moment(todayDate, "DD-MM-YYYY")) ? (
                                            <>Amount to be refunded to user: Rs.{popupData.amount}</>
                                        ) : null}
                                    </>
                                )}
                            </div>
                            {/* <Modal.Body>
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-5px" }}> <div></div><i onClick={() => setShowPopup(false)} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>

                                <center className="sme-slot-popup-heading" style={{ marginTop: "-1.3rem" }}>Booked On: {moment(popupData.bookingDate).format("DD-MMM-YYYY")}</center>

                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>  <b>Name :</b> {popupData.name}</div>
                                    <div>  <b>Slot:</b> {popupData.time}</div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div> <b>Offering text:</b> {getText(popupData.offeringId)}</div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>  <b>Link:</b> {popupData.link}</div>
                                    <div></div>
                                </div>
                                <div style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div>  <b>Agenda:</b> {popupData.agenda}</div>
                                    <div></div>
                                </div> */}

                            {/* {console.log("date", moment(popupData.bookingDate, "DD-MM-YYYY").isAfter(moment(todayDate, "DD-MM-YYYY")))} */}
                            {/* {popupData.status == 3 && moment(popupData.bookingDate, "DD-MM-YYYY")> moment(todayDate, "DD-MM-YYYY") ? (
                                <div className="m-1" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="mt-1" onClick={() => acceptData(2)}>
                                        <img src={cross} style={{ width: "40px" }} className="rounded-img-border-sme" />
                                    </div>
                                    <div className="mt-1" onClick={() => acceptData(1)}>
                                        <img src={tick} style={{ width: "40px" }} className="rounded-img-border-sme" />
                                    </div>
                                </div>
                            ) : null} */}

                            {popupData.status === 3 && isBookingDateValid && moment(popupData?.bookingDate).isAfter(moment(todayDate, "DD-MM-YYYY")) ? (
                                <div className="m-1" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="mt-1" onClick={() => acceptData(2)}>
                                        <img src={cross} style={{ width: "40px" }} className="rounded-img-border-sme" alt="cross" />
                                    </div>
                                    <div className="mt-1" onClick={() => acceptData(1)}>
                                        <img src={tick} style={{ width: "40px" }} className="rounded-img-border-sme" alt="tick" />
                                    </div>
                                </div>
                            ) : null}
                            {/* {popupData.status == 3 ?
                                <div className="m-1" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="mt-1" onClick={() => acceptData(2)} >
                                        <img src={cross} style={{ width: "40px" }} className="rounded-img-border-sme" />
                                    </div>
                                    <div className="mt-1" onClick={() => acceptData(1)}><img src={tick} style={{ width: "40px" }} className="rounded-img-border-sme" /></div>
                                </div> : ""} */}
                        </Modal.Body>
                    </Modal>
                    {/* <Modal show={showAssessmentPopup} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"

                        centered>
                        <Modal.Body>
                            <div>
                                <div style={{ display: "flex", justifyContent: "space-between", marginTop: "-5px" }}> <div></div><i onClick={() => handleCloseAssessment()} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>

                                <center className="sme-slot-popup-heading" style={{ marginTop: "-1.3rem" }}>{getText(popupData.offeringId)} with {popupData.name}  <br />{moment(popupData.bookingDate).format("DD-MMM-YYYY")} @ {popupData.time}</center>
                                <Card style={{ border: "1px solid black", borderRadius: "15px", padding: "4px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col xs={8}>
                                            <div className="">Has the meeting happened?</div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className={`mb-1`} onClick={handleTickClick}>
                                                <img src={tick}
                                                    style={{
                                                        marginTop: "-4px",
                                                        width: "30px",
                                                        border: selectedOption === true ? '2px solid #ff942c' : 'none',
                                                        borderRadius: selectedOption === true ? '50%' : '0'
                                                    }} />
                                            </div>
                                        </Col>
                                        <Col xs={2}>
                                            <div className={`mb-1`} onClick={handleCrossClick}>
                                                <img src={cross} style={{
                                                    marginTop: "-4px",
                                                    width: "30px",
                                                    border: selectedOption === false ? '2px solid #ff942c' : 'none',
                                                    borderRadius: selectedOption === false ? '50%' : '0'
                                                }} />
                                            </div>
                                        </Col>
                                    </Row>
                                </Card>
                                <div className="mt-2"></div>
                                <Card style={{ border: "1px solid black", borderRadius: "15px", padding: "4px" }}>
                                    <Row style={{ width: "100%" }}>
                                        <Col xs={6}>
                                            <div className="mb-2 mt-1">Assessment</div>
                                        </Col>
                                        {images.map((image, index) => (
                                            <Col key={index} xs={1}>
                                                <div className="mb-1" onClick={() => handleImageClick(image.value)}>
                                                    <img
                                                        src={image.src}
                                                        style={{
                                                            width: "24px",
                                                            height: "24px",
                                                            border: selectedImage === image.value ? '2px solid #ff942c' : 'none',
                                                            borderRadius: selectedImage === image.value ? '50%' : '0'
                                                        }}
                                                    />
                                                </div>
                                            </Col>
                                        ))}
                                    </Row>
                                </Card>

                                <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                                    <div className="mt-1"></div>
                                    <Button variant="default" disabled={selectedImage === null || selectedOption === null} className="mt-1 prev-btn-sme" onClick={() => assessmentData()}>Assess</Button>
                                </div>
                            </div>
                        </Modal.Body>
                    </Modal> */}


                </div>

                {/* <div className="mt-2" style={{ display: "flex", justifyContent: "space-between" }}>
                    <div></div>
                    <Button variant="default" className="mr-2 mt-1 prev-btn-sme" >Connect</Button>
                </div> */}
            </div>
            <div className="mb-bottom"></div>
            <BottomMenu />
        </div >
    )
}
const CustomInput = ({ value, onClick }) => (
    <button className="btn" onClick={onClick}>
        <i class="fa fa-calendar" id="true" aria-hidden="true"></i>
        <span className="_picker">        {value}</span>
    </button>
);
