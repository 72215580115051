import React, { Component } from "react";
import { Button, Form, Col, Card, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import circle from '../images/circle.png';
import circleblue from '../images/circle-blue.png';
import google from '../images/google.png';
import facebook from '../images/facebook.png';
import back from '../images/backarrow.png';
import axios from 'axios';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const validEmailRegex =
    /^[a-z0-9+_.-]+@[a-z0-9.-]+$/
const validPasswordRegex =
    /^.*(?=.{10,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%&!^*]).*$/
const validPhoneNoRegex = RegExp(
    /^(?:(?:\\+|0{0,2})91(\s*[\\-]\s*)?|[0]?)?[789]\d{9}$/i
);
// const validateForm = errors => {
//     let valid = true;
//     Object.values(errors).forEach(val => val.length > 0 && (valid = false));
//     return valid;
// };
export default class CreateOpportunity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            typeOfOpportunity: "Job",
            type: "1",
            company: "",
            location: "",
            tenure: "Temporary",
            url: "",
            vacancies: "",
            applyByDate: "",
            remuneration: "",
            nameOfTheContactPerson: "",
            phoneNumber: "",
            jobRole: "",
            briefJobDescription: "",
            typeOfOpportunityError: '',
            typeError: '',
            companyError: '',
            locationError: '',
            tenureError: '',
            vacanciesError: '',
            remunerationError: '',
            briefJobDescriptionError: '',
            jobRoleError: '',
            applyByDateError: '',
            home: false,
            error: "Please select the details"
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onChange = this.onChange.bind(this);
        this.handleBlur = this.handleBlur.bind(this);
        this.validateTypeOfOpportunity = this.validateTypeOfOpportunity.bind(this);
        this.validateType = this.validateType.bind(this);
        this.validateCompany = this.validateCompany.bind(this);
        this.validateLocation = this.validateLocation.bind(this);
        this.validateTenure = this.validateTenure.bind(this);
        this.validateVacancies = this.validateVacancies.bind(this);
        this.validateRemuneration = this.validateRemuneration.bind(this);
        this.validateApplyByDate = this.validateApplyByDate.bind(this);
        this.validateBriefJobDescription = this.validateBriefJobDescription.bind(this);
        this.validateJobRole = this.validateJobRole.bind(this);
        this.validateField = this.validateField.bind(this);
        this.handleContextMenu = this.handleContextMenu.bind(this);
    }
    onChange(event) {
        const { name, value } = event.target;
        this.setState({
            [name]: value
        });
        return;
    }
    // componentDidMount() {
    //     this.getOpportunityDetails();
    // }
     handleContextMenu = (e) => {
        e.stopPropagation(); // Allow right-click in this component
      };
    handleBlur(event) {
        const { name } = event.target;
        this.validateField(name);
        return;
    }

    handleSubmit(event) {
        event.preventDefault();
        //console.log(this.state);
        // let formFileds = [
        //     "typeOfOpportunity",
        //     "type",
        //     "company",
        //     "location",
        //     "tenure",
        //     "url",
        //     "jobRole",
        //     "nameOfTheContactPerson",
        //     "phoneNumber",
        //     "briefJobDescription",
        //     "startDate",
        //     "endDate",

        // ];
        // let isValid = true;
        // formFileds.forEach(field => {
        //     isValid = this.validateField(field) && isValid;
        // });
        this.saveOpportunityDetails()
    }
    getOpportunityDetails = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this;
        axios.get(config.userUrl + 'user/getSavedOpportunityDetails', { headers: headers })
            .then(function (response) {
                if (response.data === true) {
                    //  console.log(response, "opportunity created successfully!!")
                }
            })
            .catch(function (error) {
                console.log(error);
                document.getElementById('error').innerText = error
            });
    }
    saveOpportunityDetails = (e) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var payload = {
            "typeOfOpportunity": this.state.typeOfOpportunity,
            "type": parseInt(this.state.type),
            "company": this.state.company,
            "vacancies": this.state.vacancies,
            "location": this.state.location,
            "tenure": this.state.tenure,
            "url": this.state.url,
            "remuneration": this.state.remuneration,
            "nameOfTheContactPerson": this.state.nameOfTheContactPerson,
            "phoneNumber": this.state.phoneNumber,
            "briefJobDescription": this.state.briefJobDescription,
            "applyByDate": this.state.applyByDate,
            "jobRole": this.state.jobRole,
            "status": 1
        }
        var self = this;
       // console.log("payload", payload)
        axios.post(config.userUrl + 'user/saveOpportunityDetails ', payload, { headers: headers })
            .then(function (response) {
                // console.log("response", response)
                if (response.data.status === true) {
                    //  console.log(response, "opportunity created successfully!!")
                    self.setState({ home: true })
                }
            })
            .catch(function (error) {
                console.log("payload", payload, error)
                document.getElementById('error').innerText = error
            });
    }
    validateField(name) {
        let isValid = false;
        if (name === "typeOfOpportunity") isValid = this.validateTypeOfOpportunity();
        else if (name === "type") isValid = this.validateType();
        else if (name === "company") isValid = this.validateCompany();
        else if (name === "location") isValid = this.validateLocation();
        else if (name === "tenure") isValid = this.validateTenure();
        else if (name === "jobRole") isValid = this.validateJobRole();
        // else if (name === "remuneration") isValid = this.validateRemuneration();
        else if (name === "briefJobDescription") isValid = this.validateBriefJobDescription();
        else if (name === "vacancies") isValid = this.validateVacancies();
        else if (name === "applyByDate") isValid = this.validateApplyByDate();
        return isValid;
    }

    validateTypeOfOpportunity() {
        let typeOfOpportunityError = "";
        const value = this.state.typeOfOpportunity;
        if (value.trim() === "") typeOfOpportunityError = "select type of opportunity";
        this.setState({
            typeOfOpportunityError
        });
        return typeOfOpportunityError === "";
    }
    validateJobRole() {
        let jobRoleError = "";
        const value = this.state.jobRole;
        if (value.trim() === "") jobRoleError = "Please add job role";
        this.setState({
            jobRoleError
        });
        return jobRoleError === "";
    }
    validateType() {
        let typeError = "";
        const value = this.state.type;
        if (value.trim === "") typeError = "Please select type";
        this.setState({
            typeError
        });
        return typeError === "";
    }
    validateCompany() {
        let companyError = "";
        const value = this.state.company;
        if (value.trim() === "") companyError = "Please add the company";
        this.setState({
            companyError
        });
        return companyError === "";
    }
    validateLocation() {
        let locationError = "";
        const value = this.state.location;
        if (value.trim() === "") locationError = "Please add location";
        this.setState({
            locationError
        });
        return locationError === "";
    }
    validateTenure() {
        let tenureError = "";
        const value = this.state.tenure;
        if (value.trim() === "") tenureError = "Please add tenure";
        this.setState({
            tenureError
        });
        return tenureError === "";
    }
    // validateUrl() {
    //     let urlError = "";
    //     const value = this.state.url;
    //     if (value.trim() === "") urlError = "Please add url";
    //     this.setState({
    //         urlError
    //     });
    //     return urlError === "";
    // }
    validateRemuneration() {
        let remunerationError = "";
        const value = this.state.remuneration;
        if (value.trim() === "") remunerationError = "Please add remuneration";
        this.setState({
            remunerationError
        });
        return remunerationError === "";
    }
    validateVacancies() {
        let vacanciesError = "";
        const value = this.state.vacancies;
        if (value.trim() === "") vacanciesError = "Please add vacancies";
        this.setState({
            vacanciesError
        });
        return vacanciesError === "";
    }
    validateBriefJobDescription() {
        let briefJobDescriptionError = "";
        const value = this.state.briefJobDescription;
        if (value.trim() === "") briefJobDescriptionError = "Please add Brief JobDescription";
        this.setState({
            briefJobDescriptionError
        });
        return briefJobDescriptionError === "";
    }
    validateApplyByDate() {
        let applyByDateError = "";
        const value = this.state.applyByDate;
        if (value.trim === null) applyByDateError = "Please enter valid date";
        this.setState({
            applyByDateError
        });
        return applyByDateError === "";
    }
    onPaste = (e) => {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');
        this.setState(prevState => ({
            briefJobDescription: prevState.briefJobDescription + text,
            briefJobDescriptionError: ''
        }));
    };
    onPaste = (e) => {
        e.preventDefault();
        setTimeout(() => {
            const pastedText = e.target.value;
            this.setState({
                briefJobDescription: pastedText,
                briefJobDescriptionError: ''
            });
        }, 0);
    };
    render() {
        const { home } = this.state
        if (home) {
            return <Redirect to="/home" />
        }
        return (
            // <div>
            //     <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
            //     <Form onSubmit={this.handleSubmit} style={{ marginTop: "5rem" }}>
            //         <Link to="/home"><img alt="" src={back} className="back_arrow_" /></Link><div className="your-fav ml-5" style={{ position: "fixed", top: "3.4vh" }}> <div className="screen-topgap ml-4">Create an Opportunity</div></div>
            //         <div className="card-opp-top"></div>
            //         <div style={{ marginLeft: "5%", marginRight: "5%", width: "90%" }} >
            //             <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} > <div className="form-group mt-2">
            //                 <input type="text"
            //                     className="form-control form-bg-opp"
            //                     aria-describedby="text"
            //                     value={this.state.company}
            //                     name="company"
            //                     placeholder="Organization Name"
            //                     onChange={this.onChange}
            //                     onBlur={this.handleBlur}
            //                 />
            //                 <div className="div">{this.state.companyError && (
            //                     <div className="error">{this.state.companyError}</div>
            //                 )}</div>
            //             </div>

            //                 <div className="form-group mt-3">
            //                     <input type="text"
            //                         className="form-control form-bg-opp"
            //                         aria-describedby="text"
            //                         value={this.state.url}
            //                         name="url"
            //                         placeholder="Organization Url"
            //                         onChange={this.onChange}
            //                         onBlur={this.handleBlur}
            //                     />
            //                 </div>
            //             </div>
            //             </Card>
            //             <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >
            //                 <div className="form-group">
            //                     <div className="form-check form-check-inline mt-1 form_check" style={{ display: "flex" }}>
            //                         <input name="typeOfOpportunity" className="form-check" type="radio" value="Job" checked={this.state.typeOfOpportunity === "Job"} onChange={this.onChange} />
            //                         <label class="ml-1 form-check-label" for="flexRadioDefault1">
            //                             Job
            //                         </label>
            //                         <input name="typeOfOpportunity" className="ml-3  form-check" type="radio" value="Internship" checked={this.state.typeOfOpportunity === "Internship"} onChange={this.onChange} />
            //                         <label class="ml-1 form-check-label" for="flexRadioDefault1">
            //                             Internship
            //                         </label>
            //                     </div>
            //                     <div className="form-check form-check-inline form_check mt-2" style={{ display: "flex" }}>
            //                         <input name="tenure" className="form-check" type="radio" value="Temporary" checked={this.state.tenure === "Temporary"} onChange={this.onChange} />
            //                         <label class="ml-1 form-check-label" for="flexRadioDefault1">
            //                             Temporary
            //                         </label>
            //                         <input name="tenure" className="ml-2 form-check" type="radio" value="Permanent" checked={this.state.tenure === "Permanent"} disabled={this.state.typeOfOpportunity === "Internship" || this.state.typeOfOpportunity === "Consultant"} onChange={this.onChange} />
            //                         <label class="ml-1 form-check-label" for="flexRadioDefault1">
            //                             Permanent
            //                         </label>
            //                     </div>
            //                     <div className="form-check form-check-inline form_check mt-2" style={{ display: "flex" }}>
            //                         <input name="type" className="form-check" type="radio" value="1" checked={this.state.type === "1"} onChange={this.onChange} />
            //                         <label class="ml-1 form-check-label" for="flexRadioDefault1">
            //                             Part-time
            //                         </label>
            //                         <input name="type" className="ml-3 form-check" type="radio" value="2" checked={this.state.type === "2"} onChange={this.onChange} />
            //                         <label class="ml-1 form-check-label" for="flexRadioDefault1">
            //                             Full-time
            //                         </label>
            //                     </div>
            //                     {((this.state.typeOfOpportunity.length === null) || (this.state.type.length === 0) || (this.state.tenure.length === 0)) ?
            //                         <div className="div error">{this.state.error}</div> : <div></div>}
            //                     <Row style={{ width: "100%" }} className="mt-1">
            //                         <Col xs={6}>
            //                             <div className="form-group">
            //                                 <input type="number"
            //                                     style={{ width: "118%" }}
            //                                     className="form-control form-bg-opp"
            //                                     aria-describedby="text"
            //                                     value={this.state.vacancies}
            //                                     name="vacancies"
            //                                     placeholder="Vacancies"
            //                                     onChange={this.onChange}
            //                                     onBlur={this.handleBlur}
            //                                 />
            //                                 <div className="div">{this.state.vacanciesError && (
            //                                     <div className="error">{this.state.vacanciesError}</div>
            //                                 )}</div>
            //                             </div>
            //                         </Col>
            //                         <Col xs={6}>
            //                             <div className="form-group">
            //                                 <input type="text"
            //                                     style={{ width: "118%" }}
            //                                     className="ml-1 form-control form-bg-opp"
            //                                     aria-describedby="text"
            //                                     value={this.state.location}
            //                                     name="location"
            //                                     placeholder="Location"
            //                                     onChange={this.onChange}
            //                                     onBlur={this.handleBlur}
            //                                 />
            //                                 <div className="div">{this.state.locationError && (
            //                                     <div className="error">{this.state.locationError}</div>
            //                                 )}</div>

            //                             </div>
            //                         </Col>
            //                     </Row>
            //                     <Row style={{ width: "100%" }}>
            //                         <Col xs={6}>
            //                             <select
            //                                 style={{ width: "118%" }}
            //                                 className="form-control form-bg-opp select-left-icon"
            //                                 value={this.state.remuneration}
            //                                 name="remuneration"
            //                                 onChange={this.onChange}
            //                                 aria-describedby="text"
            //                             >
            //                                 <option value="" disabled>Select Remuneration (INR)</option>
            //                                 <option value="10000">10,000</option>
            //                                 <option value="20000">20,000</option>
            //                                 <option value="30000">30,000</option>
            //                             </select>
            //                             <div className="div">
            //                                 {this.state.remunerationError && (
            //                                     <div className="error">{this.state.remunerationError}</div>
            //                                 )}
            //                             </div>
            //                         </Col>
            //                         <Col xs={6}>
            //                             <DatePicker
            //                                 className="ml-1 form-control form-bg-opp-date"
            //                                 dateFormat="dd/MM/yyyy"
            //                                 selected={this.state.applyByDate}
            //                                 minDate={new Date()}
            //                                 onChange={(date) => this.setState({ applyByDate: date })}
            //                                 placeholderText="Last date to apply"
            //                             />
            //                             <div className="div">{this.state.applyByDateError && (
            //                                 <div className="error">{this.state.applyByDateError}</div>
            //                             )}</div>
            //                         </Col>
            //                     </Row>
            //                     <div className="form-group mt-3">
            //                         <input type="text"
            //                             className="form-control form-bg-opp"
            //                             aria-describedby="text"
            //                             value={this.state.jobRole}
            //                             name="jobRole"
            //                             placeholder="Job role"
            //                             onChange={this.onChange}
            //                             onBlur={this.handleBlur}
            //                         />
            //                         <div className="div">{this.state.jobRoleError && (
            //                             <div className="error">{this.state.jobRoleError}</div>
            //                         )}</div>
            //                     </div>
            //                     <div className="form-group mt-3">
            //                         <textarea rows={2} type="text"
            //                             className="form-control"
            //                             style={{ resize: "none", fontSize: "12px" }}
            //                             value={this.state.briefJobDescription}
            //                             name="briefJobDescription"
            //                             placeholder="Brief Job Description"
            //                             onChange={this.onChange}
            //                             onBlur={this.handleBlur}
            //                             onInput={(e) => this.setState({ briefJobDescription: e.target.value })}
            //                         />
            //                         <div className="div">{this.state.briefJobDescriptionError && (
            //                             <div className="error">{this.state.briefJobDescriptionError}</div>
            //                         )}</div>
            //                     </div>
            //                 </div>
            //             </div>
            //             </Card >
            //             <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >
            //                 <Row style={{ width: "100%" }}>
            //                     <Col xs={6}>
            //                         <div className="form-group mt-2">
            //                             <input type="text"
            //                                 style={{ width: "118%" }}
            //                                 className="form-control form-bg-opp"
            //                                 aria-describedby="text"
            //                                 value={this.state.nameOfTheContactPerson}
            //                                 name="nameOfTheContactPerson"
            //                                 placeholder="Contact person"
            //                                 onChange={this.onChange}
            //                             />
            //                         </div>
            //                     </Col>
            //                     <Col xs={6}>
            //                         <div className="form-group mt-2">
            //                             <input type="text"
            //                                 style={{ width: "118%" }}
            //                                 className="ml-1 form-control form-bg-opp"
            //                                 aria-describedby="text"
            //                                 value={this.state.phoneNumber}
            //                                 name="phoneNumber"
            //                                 placeholder="Contact person phone"
            //                                 onChange={this.onChange}
            //                             />
            //                         </div>
            //                     </Col>
            //                 </Row>
            //             </div>
            //             </Card>

            //         </div >
            //         {/* <div className="ml-4"><input type="checkbox" checked={this.state.checkboxToshowDetails} onClick={this.handleCheck} /> <span className="signup-agree-text"> &nbsp;Show contact details</span></div> */}
            //         <center>
            //             {/* <div id="status" className="errormsg"></div> */}
            //             {/* <div id="error" className="errormsg"></div> */}
            //             <Button variant="default" type="submit" className="btn-block opportunity-btn mt-2">Create</Button><br />
            //         </center>
            //     </Form >
            // </div >
            <div onContextMenu={this.handleContextMenu}> 
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <Form onSubmit={this.handleSubmit} style={{ marginTop: "5rem" }}>
                    <Link to="/home"><img alt="" src={back} className="back_arrow_" /></Link><div className="your-fav ml-5" style={{ position: "fixed", top: "3.4vh" }}> <div className="screen-topgap ml-4">Create an Opportunity</div></div>
                    <div className="card-opp-top"></div>
                    <div style={{ marginLeft: "5%", marginRight: "5%", width: "90%" }} >
                        <Card className="createopportunity-card">
                            <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                                <div className="form-group mt-2">
                                    <input type="text"
                                        className="form-control opp-fs"
                                        aria-describedby="text"
                                        value={this.state.company}
                                        name="company"
                                        placeholder="Organization Name"
                                        onChange={this.onChange}
                                    />
                                    <input type="text"
                                        className="form-control opp-fs"
                                        aria-describedby="text"
                                        value={this.state.url}
                                        name="url"
                                        placeholder="Posting Url"
                                        onChange={this.onChange}
                                    />
                                    <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                        <Col xs={6}>
                                            <input type="text"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={this.state.nameOfTheContactPerson}
                                                name="nameOfTheContactPerson"
                                                placeholder="Contact person"
                                                onChange={this.onChange}
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <input type="text"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={this.state.phoneNumber}
                                                name="phoneNumber"
                                                placeholder="Contact Phone/email"
                                                onChange={this.onChange}
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                        <Card className="createopportunity-card">
                            <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                                <div className="form-group mt-2">
                                    <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                        <Col xs={8}>
                                            <input type="text"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={this.state.jobRole}
                                                name="jobRole"
                                                placeholder="Role Title"
                                                onChange={this.onChange}
                                            />
                                        </Col>
                                        <Col xs={4}>
                                            <input type="number"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={this.state.vacancies}
                                                name="vacancies"
                                                placeholder="Position(s)"
                                                onChange={this.onChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                        <Col xs={4}>
                                            <input type="number"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={this.state.remuneration}
                                                name="remuneration"
                                                placeholder="Salary"
                                                onChange={this.onChange}
                                            />
                                        </Col>
                                        <Col xs={8}>
                                            <input type="text"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={this.state.location}
                                                name="location"
                                                placeholder="Location"
                                                onChange={this.onChange}
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                        <Col xs={2}>
                                            <select
                                                style={{ padding: '1px' }}
                                                name="typeOfOpportunity"
                                                className="form-control opp-fs"
                                                value={this.state.typeOfOpportunity}
                                                onChange={this.onChange}
                                            >
                                                <option value="Job">Job</option>
                                                <option value="Internship">Internship</option>
                                            </select>
                                        </Col>
                                        <Col xs={3}>
                                            <select
                                                style={{ padding: '1px' }}
                                                name="tenure"
                                                className="form-control opp-fs"
                                                onChange={this.onChange}
                                                disabled={this.state.typeOfOpportunity === "Internship" ? true : false}
                                                value={this.state.typeOfOpportunity === "Internship" || this.state.typeOfOpportunity === "Consultant" ? "Temporary" : this.state.tenure}
                                            >
                                                <option value="Temporary">Temporary</option>
                                                <option value="Permanent">Permanent</option>
                                            </select>
                                        </Col>
                                        <Col xs={3}>
                                            <select
                                                style={{ padding: '1px' }}
                                                name="type"
                                                className="form-control opp-fs"
                                                value={this.state.type}
                                                onChange={this.onChange}
                                            >
                                                <option value="1">Part-time</option>
                                                <option value="2">Full-time</option>
                                            </select>
                                        </Col>
                                        <Col xs={4}>
                                            <DatePicker
                                                className="form-control opp-date"
                                                dateFormat="dd/MM/yyyy"
                                                selected={this.state.applyByDate}
                                                minDate={new Date()}
                                                onChange={(date) => this.setState({ applyByDate: date })}
                                                placeholderText="dd/mm/yyyy"
                                            />
                                        </Col>
                                    </Row>
                                    <textarea rows={12} type="text"
                                        className="form-control"
                                        style={{ resize: "none", fontSize: "14px" }}
                                        value={this.state.briefJobDescription}
                                        name="briefJobDescription"
                                        placeholder="Brief Job Description"
                                        onChange={this.onChange}
                                        onInput={(e) => this.setState({ briefJobDescription: e.target.value })}
                                    />

                                </div>
                            </div>
                        </Card>
                    </div >
                    {/* <center>
                        <Button variant="default" type="submit" className="btn-block opportunity-btn mt-2">Create</Button><br />
                    </center> */}
                    <center>
                        {((this.state.company !== "") && (this.state.url !== "") && (this.state.nameOfTheContactPerson !== "") && (this.state.phoneNumber !== "") && (this.state.jobRole !== "") && (this.state.vacancies !== "") && (this.state.location !== "") && (this.state.briefJobDescription !== "") && (this.state.applyByDate !== "")) ?
                            <Button variant="default" type="submit" className="btn-block opportunity-btn mt-1">Create</Button>
                            : <Button variant="default" type="submit" disabled={true} className="btn-block opportunity-btn mt-1">Create</Button>}
                        <br />
                    </center>
                </Form >
            </div >
        );
    }
}
