import React, { useState, useEffect } from "react";
import { Button, Navbar, Carousel, Nav, Form, Container, Card, Col, Row, Image } from "react-bootstrap";
import './LandingPage.css';
import games from '../images/games-u.svg';
import rewards from '../images/rewards-u.svg';
import qna from '../images/qna-u.svg';
// import quizzes from '../images/quiz-services.svg';
// import networking from '../images/networking-services.svg';
import networking from '../images/networking-u.svg';
import quizzes from '../images/quiz-u.svg';
import adds from '../images/add-u.svg';
import opportunity from '../images/opportunity-u.svg';
import axios from 'axios';
import { config } from '../Config/Config';
import Loading from "../Loading/Loading";
import { Link } from 'react-router-dom';
import intro from '../images/sceem intro.svg';
import features from '../images/sceem features.svg';
import preferenceImg from '../images/sceem preferences.svg';
import legalaudit from '../images/Legal and Audit.svg';
import people from '../images/People.svg';
import globaltrends from '../images/Global trends.svg';
import governance from '../images/Governance.svg';
import leadership from '../images/Leadership_new.svg';
import fullfilment from '../images/Fullfilment.svg';
import gogreen from '../images/Go Green.svg';
import industry from '../images/Industry 4.0.svg';
import customercentric from '../images/Customer centric_new.svg';
import Login from "../Login/Login";
import topicsCovered from '../images/topics covered.png';
import featuresS from '../images/features.png';
import chain from '../images/4E Chain.svg';

export default function LandingPage() {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => { setIsLoading(false) }, 4000);
    }, []);
    if (isLoading) {
        return <Loading />
    }
    return (
        <div>
            {!localStorage.getItem("junkStore") ?
                <div style={{ fontFamily: "Noto Sans" }}>
                    <Carousel variant="dark" controls={false} pause="hover" >
                        <Carousel.Item variant="dark" interval={3000} style={{ height: "92vh" }}>
                            <div>
                                <div className="mt-2 ml-2 mr-2">     <img src="lp1.png" alt="" style={{ width: "100%" }} /></div>
                                <br /><br />
                                <div className="l-page-top"><b>Strategic 4Es Platform</b></div>
                                <div className="mt-4 l-page-text">Connecting Enterprises, Experts, Enthusiasts, Entrepreneurs in one "Knowledge Chain".
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item variant="dark" interval={3000} style={{ height: "92vh" }}>
                            <div>
                                <div className="mt-2 ml-2 mr-2">     <img src="lp2.png" alt="" style={{ width: "100%" }} /></div>
                                <br /><br />
                                <div className="l-page-top"><b>Best Features</b></div>
                                <div className="mt-4 l-page-text">Build your network, share knowledge, opportunities, play interesting games, get rewarded and recognised.
                                </div>
                            </div>
                        </Carousel.Item>
                        <Carousel.Item variant="dark" interval={3000} style={{ height: "92vh" }}>
                            <div>
                                <div className="mt-2 ml-2 mr-2">     <img src="lp3.png" alt="" style={{ width: "100%" }} /></div>
                                <br /><br />
                                <div className="l-page-top"><b>Nurture your Interests</b></div>
                                <div className="mt-4 l-page-text">Choose from wide range of topics Go green, Industrial 4.0, Cybersecurity, Governance, People and Customer Centric.
                                </div>
                            </div>
                        </Carousel.Item>
                    </Carousel>
                    <Link to="/login"><div style={{ position: "fixed", bottom: "0.5rem", fontFamily: "Noto Sans", fontSize: "18px", fontWeight: 600, right: "5%", color: "#31197C" }}>Skip</div></Link>



                </div> :
                <Login />

            }
        </div>
    )
}