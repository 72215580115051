import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import moment from 'moment-timezone';
import { config } from '../Config/Config';
import DatePicker from "react-datepicker";
import pendingcircle from '../images/pending-cir.svg';
import rejectedcircle from '../images/rejected-cir.svg';
import publishedcircle from '../images/published-cir.svg';
import SimpleCrypto from "simple-crypto-js";
import { AiOutlineShareAlt } from "react-icons/ai";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class DetailedOpportunity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            opportunity: [],
            status: '',
            isLoading: true
        }
        this.goBack = this.goBack.bind(this)
    }
    goBack = () => {
        window.history.back();
    }
    componentDidMount() {
        //  console.log("opportunity")
        this.getOpportunityById();
    }

    getOpportunityById = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this;
        axios.get(config.userUrl + `user/getOpportunityById/${this.props.match.params.id}`, { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    self.setState({
                        opportunity: response.data.getOpportunityById,
                        status: response.data.getOpportunityById[0]?.status,
                        isLoading: false
                    })
                    // console.log("res", response.data)
                }
            })
            .catch(function (error) {
                console.log("Error", error);
            });
    };
    shareOpportunity = (d) => {
        if (navigator.share) {
            navigator.share({
                url: `https://www.sceem.org/jobopportunity/${d}/${this.props.match.params.id}`,
            }).then(() => {
                // console.log('Thanks for sharing!', d);
            }).catch(err => {
                console.log("Error while using Web share API:", err);

            });
        } else {
            console.log("Sorry! Your browser does not support Web Share API");
        }
    }
    render() {
        const { opportunity, status } = this.state
        const updatedDate = opportunity.map((i, idx) => {
            var a = new Date(i.applyByDate)
            var start = parseInt(a.getMonth() + 1) + "-" + a.getDate() + "-" + a.getFullYear();
            return start;
        })

        const selectedStartDate = new Date(updatedDate)
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo" /></div>

                <div style={{ marginTop: "3rem", marginLeft: "25px", display: "flex", justifyContent: 'space-between' }}>
                    <div><b style={{ color: "#ff942C", fontSize: "16px" }}>Opportunity Details</b>
                        <span >
                            {status == 2 ? <img src={pendingcircle} className="adv-circles-opp" />
                                : (status == 1 ? <img src={publishedcircle} className="adv-circles-opp" />
                                    : <img src={rejectedcircle} className="adv-circles-opp" />)}
                        </span>
                    </div>
                    <div style={{ marginRight: "0.8rem" }}><AiOutlineShareAlt size="20px" onClick={() => this.shareOpportunity(moment(opportunity[0]?.createdAt).format("YYYYMMDD"))} /></div>
                </div>
                {opportunity.map((data, i) => (
                    <div style={{ marginLeft: "5%", marginRight: "5%", width: "90%" }} >
                        {/* <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} > <div className="form-group mt-1">
                            <label className="view-opp-label">Organization Name</label>
                            <input type="text"
                                className="form-control form-bg-opp"
                                aria-describedby="text"
                                value={data.company}
                                readOnly
                                name="company"
                                placeholder="Organization Name"
                            />
                        </div>

                            <div className="form-group mt-3">
                                <label className="view-opp-label">Organization Url</label>
                                <input type="text"
                                    className="form-control form-bg-opp"
                                    aria-describedby="text"
                                    value={data.url}
                                    readOnly
                                    name="url"
                                    placeholder="Organization Url"
                                />
                            </div>
                        </div>
                        </Card>
                        <Card className="createopportunity-card"> <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                            <div className="form-group mt-2">
                                <div style={{ fontSize: "12px" }}>{data.type === 1 ? <span>Part-Time {data.typeOfOpportunity} for {data.tenure} </span> : <span>Full-Time {data.typeOfOpportunity} for {data.tenure} </span>}</div>

                                <Row style={{ width: "100%" }} className="mt-2">
                                    <Col xs={6}>
                                        <div className="form-group">
                                            <input type="number"
                                                style={{ width: "118%" }}
                                                className="form-control form-bg-opp"
                                                aria-describedby="text"
                                                name="vacancies"
                                                readOnly
                                                placeholder={`${data.vacancies} Vacancies`}
                                            />
                                        </div>
                                    </Col>
                                    <Col xs={6}>
                                        <div className="form-group">
                                            <input type="text"
                                                style={{ width: "118%" }}
                                                className="ml-1 form-control form-bg-opp"
                                                aria-describedby="text"
                                                value={data.location}
                                                name="location"
                                                readOnly
                                                placeholder="Location"
                                            />

                                        </div>
                                    </Col>
                                </Row>
                                <Row style={{ width: "100%" }}>
                                    <Col xs={6}>
                                        <input type="text"
                                            style={{ width: "118%" }}
                                            className="form-control form-bg-opp"
                                            aria-describedby="text"
                                            name="remuneration"
                                            readOnly
                                            placeholder={`${data.remuneration} (INR/month)`}
                                        />
                                    </Col>
                                    <Col xs={6}>
                                        <DatePicker
                                            className="ml-1 form-control form-bg-opp-date"
                                            dateFormat="dd-MM-yyyy"
                                            readOnly
                                            selected={selectedStartDate}
                                            minDate={new Date()}
                                            placeholderText="Last date to apply"
                                        />
                                    </Col>
                                </Row>
                                <div className="form-group mt-3">
                                    <input type="text"
                                        className="form-control form-bg-opp"
                                        aria-describedby="text"
                                        value={data.jobRole}
                                        name="jobRole"
                                        placeholder="Job role"
                                        readOnly
                                    />
                                </div>
                                <div className="form-group mt-3">
                                    <textarea rows={2} type="text"
                                        className="form-control"
                                        style={{ resize: "none", fontSize: "12px" }}
                                        value={data.briefJobDescription}
                                        readOnly
                                        name="briefJobDescription"
                                        placeholder="Brief Job Description"
                                    />

                                </div>
                            </div>
                        </div>
                        </Card >
                        <Card className="createopportunity-card"> <div style={{ marginTop: "-5px", marginLeft: "3%", marginRight: "3%" }} >
                            <Row style={{ width: "100%" }}>
                                <Col xs={6}>
                                    <div className="form-group">
                                        <label className="ml-2 view-opp-label">Name</label>
                                        <input type="text"
                                            style={{ width: "118%" }}
                                            className="form-control form-bg-opp"
                                            aria-describedby="text"
                                            readOnly
                                            value={data.nameOfTheContactPerson}
                                            name="nameOfTheContactPerson"
                                            placeholder="Contact person"
                                        />
                                    </div>
                                </Col>
                                <Col xs={6}>
                                    <div className="form-group">
                                        <label className="ml-2 view-opp-label">Email/Number</label>
                                        <input type="text"
                                            style={{ width: "118%" }}
                                            className="ml-1 form-control form-bg-opp"
                                            aria-describedby="text"
                                            readOnly
                                            value={data.phoneNumber}
                                            name="phoneNumber"
                                            placeholder="Contact person phone"
                                        />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                        </Card> */}
                        <Card className="createopportunity-card">
                            <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                                <div className="form-group mt-2">
                                    <input type="text"
                                        className="form-control opp-fs"
                                        aria-describedby="text"
                                        value={data.company}
                                        readOnly
                                        name="company"
                                        placeholder="Organization Name"
                                    />
                                    <input type="text"
                                        className="form-control opp-fs"
                                        aria-describedby="text"
                                        value={data.url}
                                        readOnly
                                        name="url"
                                        placeholder="Posting Url"
                                    />
                                    <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                        <Col xs={6}>
                                            <input type="text"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={data.nameOfTheContactPerson}
                                                readOnly
                                                name="nameOfTheContactPerson"
                                                placeholder="Contact person"
                                            />
                                        </Col>
                                        <Col xs={6}>
                                            <input type="text"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={data.phoneNumber}
                                                readOnly
                                                name="phoneNumber"
                                                placeholder="Contact Phone/email"
                                            />
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Card>
                        <Card className="createopportunity-card">
                            <div style={{ marginLeft: "3%", marginRight: "3%" }} >
                                <div className="form-group mt-2">
                                    <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                        <Col xs={8}>
                                            <input type="text"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={data.jobRole}
                                                readOnly
                                                name="jobRole"
                                                placeholder="Role Title"
                                            />
                                        </Col>
                                        <Col xs={4}>
                                            <input type="number"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={data.vacancies}
                                                readOnly
                                                name="vacancies"
                                                placeholder="Position(s)"
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                        <Col xs={4}>
                                            <input type="number"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={`${data.remuneration} (INR/month)`}
                                                name="remuneration"
                                                readOnly
                                                placeholder={`${data.remuneration} (INR/month)`}
                                            />
                                        </Col>
                                        <Col xs={8}>
                                            <input type="text"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={data.location}
                                                readOnly
                                                name="location"
                                                placeholder="Location"
                                            />
                                        </Col>
                                    </Row>
                                    <Row style={{ width: "100%", marginTop: "-3px" }} className="no-gutters">
                                        <Col xs={2}>
                                            <input type="text"
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={data.typeOfOpportunity}
                                                readOnly
                                                name="typeOfOpportunity"
                                            />
                                        </Col>
                                        <Col xs={3}>
                                            <input type="text"
                                                className="form-control opp-fs custom-form-control"
                                                aria-describedby="text"
                                                value={data.tenure}
                                                readOnly
                                                name="tenure"
                                            />
                                        </Col>
                                        <Col xs={3}>
                                            <input type="text"
                                                style={{ padding: 'auto 2px' }}
                                                className="form-control opp-fs"
                                                aria-describedby="text"
                                                value={data.type == 1 ? "Part-time" : "Full-time"}
                                                readOnly
                                                name="type"
                                            />
                                        </Col>
                                        <Col xs={4}>
                                            <DatePicker
                                                className="form-control opp-date"
                                                dateFormat="dd-MM-yyyy"
                                                readOnly
                                                selected={selectedStartDate}
                                                minDate={new Date()}
                                                placeholderText="Last date to apply"
                                            />
                                        </Col>
                                    </Row>
                                    <textarea rows={12} type="text"
                                        className="form-control"
                                        style={{ resize: "none", fontSize: "14px" }}
                                        value={data.briefJobDescription}
                                        readOnly
                                        name="briefJobDescription"
                                        placeholder="Brief Job Description"
                                    />

                                </div>
                            </div>
                        </Card>

                    </div >))}
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div >
        )
    }
}
