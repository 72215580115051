import React, { useState, useEffect } from "react";
import BottomMenu from "../BottomMenu/BottomMenu";
import loginlogo from '../images/login-logo.png';
import profilePicture from '../images/author1.svg'
import { Card, Modal, Row, Col } from "react-bootstrap";
import moment from 'moment-timezone';
import googlelinkImg from '../images/googlelink.svg';
import linktext from '../images/linktext.svg';
import nodata from '../images/no-data.png';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import { config } from '../Config/Config';
import 'react-datepicker/dist/react-datepicker.css';
import back from '../images/backarrow.png';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

const ViewSmeEvents = (props) => {
    const [token, setToken] = useState(simpleCrypto.decrypt(localStorage.getItem('junkStore')))
    const [showPopup, setShowPopup] = useState(false)
    const [popupData, setPopupData] = useState([])
    const [allDays, setAllDays] = useState([]);
    const [allTimeSlots, setAllTimeSlots] = useState([]);
    const [allOfferings, setAllOfferings] = useState([]);
    const [selectedOfferings, setSelectedOfferings] = useState([]);
    const [commercialPlans, setCommercialPlans] = useState([])
    const [eventsData, setEventsData] = useState([]);
    const months = [
        "January", "February", "March", "April", "May", "June",
        "July", "August", "September", "October", "November", "December"
    ];
    const history = useHistory();
    const currentMonthIndex = new Date().getMonth();
    const [selectedMonth, setSelectedMonth] = useState(currentMonthIndex);
    // const [eventsData, setEventsData] = useState([
    //     { id: 1, date: "2024-09-01", type: "Network", desc: "Event 1 Description", time: "4:30 PM", name: "Srigowri", link: "https://www.google.com", "registered": 3 },
    //     { id: 2, date: "2024-09-15", type: "Coach", desc: "Event 2 Description", time: "10:30 AM", name: "Srigowri", link: "https://www.google.com", "registered": 5 },
    //     { id: 3, date: "2024-10-05", type: "Advisory", desc: "Event 3 Description", time: "11:30 AM", name: "Srigowri", link: "https://www.google.com", "registered": 0 }
    //     // Add more events as needed
    // ]);

    useEffect(() => {
        getAllSmeOfferings();
        getAllDays();
        getAllCommercialPlans();
        getSmeCreatedEventsDataByUser(currentMonthIndex);

    }, [])
    const getAllSmeOfferings = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllSmeOfferings', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status === true) {
                    setAllOfferings(response.data.offerings)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllDays = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllDays', { headers: headers })
            .then(function (response) {
                //   console.log(response);
                if (response.data.status === true) {
                    setAllDays(response.data.days)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllTimeSlots = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllTimeSlots', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setAllTimeSlots(response.data.timeSlots)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getAllCommercialPlans = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        axios.get(config.userUrl + 'user/getAllCommercialPlans', { headers: headers })
            .then(function (response) {
                //console.log(response);
                if (response.data.status === true) {
                    setCommercialPlans(response.data.commercialPlans)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    const getSmeCreatedEventsDataByUser = (id) => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + token,
            "applicationType": "mobile"
        }
        var payload = {
            "month": id,
            "smeUserId": props.match.params.id
        }
        axios.post(config.userUrl + 'user/getSmeCreatedEventsDataByUser', payload, { headers: headers })
            .then(function (response) {
                // console.log(response);
                if (response.data.status === true) {
                    setEventsData(response.data.eventsData)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    // const changeEventStatusBySme = (id) => {
    //     let headers = {
    //         "Content-Type": 'application/json',
    //         "Authorization": "Bearer " + token,
    //         "applicationType": "mobile"
    //     }
    //     var payload = {
    //         "status": 2,
    //         "id":id
    //     }
    //     axios.post(config.userUrl + 'user/changeEventStatusBySme', payload, { headers: headers })
    //         .then(function (response) {
    //             // console.log(response);
    //             if (response.data.status === true) {
    //                 getSmeCreatedEventsData(currentMonthIndex)
    //             }
    //         })
    //         .catch(function (error) {
    //             console.log("Error");
    //         });
    // };
    const getText = (offeringId) => {
        const item = allOfferings.find(txt => txt.id === offeringId);
        return item ? item.text : "";
    }
    const handleMonthChange = (e) => {
        setSelectedMonth(parseInt(e.target.value, 10));
        getSmeCreatedEventsDataByUser(parseInt(e.target.value, 10));

    };

    const handleDeleteEvent = (id) => {
        setEventsData(eventsData.filter(event => event.id !== id));
    };
    const handlePopup = (data) => {
        setShowPopup(true)
        setPopupData(data)
    }
    const openLinkUrl = (link) => {
        window.open(`${link}`)
    }
    const goBack = () => {
        history.goBack()
    }
    return (
        <div >
            <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
            <div className="screen-topgap">

                <div className="your-jitbits">View SME Events</div>
                {/* <img
                    alt="Back"
                    src={back}
                    className="back_arrow_"
                    onClick={() => goBack()}
                    style={{ cursor: 'pointer' }}
                /><div className="your-fav ml-5" style={{ position: "fixed", top: "3.4vh" }}> <div className="screen-topgap ml-4">View SME Events</div></div>
                <br /> */}
                <div className="events-container">
                    <div style={{ marginRight: "18px", float: "right" }}>
                        <select className="form-control" value={selectedMonth} onChange={handleMonthChange}>
                            {months.map((month, index) => (
                                <option key={index} value={index}>
                                    {month}
                                </option>
                            ))}
                        </select>
                    </div><br />
                    {/* Display events for the selected month */}
                    {eventsData.filter(event => new Date(event.bookingDate).getMonth() === selectedMonth).length === 0 ? (
                        <div>
                            <img src={nodata} style={{ marginTop: "14%", width: "96%", marginLeft: "2%", marginRight: "2%" }} />
                            <center style={{ fontSize: "20px", marginTop: "1rem" }}>No events</center>
                        </div>
                    ) : (
                        eventsData.map((event, index) => {
                            const eventMonth = new Date(event.bookingDate).getMonth();

                            if (eventMonth === selectedMonth) {
                                return (
                                    <div key={event.id} className="ml-3 mr-3 link"><br />
                                        <Card style={{ padding: "2px 0px 2px 10px" }} className="view_opp_card" >
                                            <Row style={{ width: "100%" }} className="no-gutters">
                                                <Col xs={11} onClick={() => handlePopup(event)}>
                                                    <div><strong>{getText(event.offeringId)}</strong></div>
                                                    <strong>{moment(event.bookingDate).format("DD-MMM-YYYY")} @{event.time}</strong>
                                                    <div><strong>{event.registeredcount} user(s) registered</strong></div>
                                                </Col>
                                                {/* <Col xs={1}>
                                                    {selectedMonth === currentMonthIndex && (
                                                        <i
                                                            className="fa fa-trash-o"
                                                            aria-hidden="true"
                                                            onClick={() => changeEventStatusBySme(event.id)}
                                                            style={{ cursor: "pointer", marginTop: "10px" }}
                                                        >
                                                        </i>
                                                    )}
                                                </Col> */}
                                            </Row>
                                        </Card>
                                    </div>
                                );
                            } else {
                                return null;
                            }
                        })
                    )}
                    <Modal show={showPopup} size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        style={{ margin: 0 }}
                        centered>
                        <Modal.Body style={{ background: "#EFF5FB", borderRadius: "16px", padding: 0 }}>
                            <div className="sme-booking-slot-popup-planned">
                                <div className="ml-1"> </div>
                                <div style={{ fontSize: "18px", marginTop: "-4px", textAlign: "center" }}>{getText(popupData.offeringId)} Event</div>
                                <div style={{ display: "flex", justifyContent: "space-between", color: "white", marginTop: "-4px", marginRight: "0.8rem", cursor: "pointer" }}> <div></div><i onClick={() => setShowPopup(false)} className="cursor-pointer fa fa-times fa-lg" aria-hidden="true"></i></div>
                            </div> <Row style={{ width: "100%", marginTop: "2rem", height: "6.2rem" }} className="no-gutters">
                                <Col xs={4}>
                                    <center><img alt="" src={config.userUrl + popupData.profilePicture} style={{ width: "58px", marginBottom: "5px" }} /></center>
                                    <center style={{ fontSize: "15px", lineHeight: "18px", fontFamily: "Noto Sans", fontWeight: 600 }} className="mt-2">{popupData?.name}</center>
                                </Col>
                                <Col xs={4}>
                                    <center>
                                        <div className="mt-2" style={{ lineHeight: "18px", fontSize: "15px", fontFamily: "Noto Sans", fontWeight: 600 }}>Participants</div>
                                        <div className="mt-2" style={{ lineHeight: "18px", fontSize: "15px", fontFamily: "Noto Sans", fontWeight: 600 }}>Registered</div>
                                        <div className="mt-3" style={{ lineHeight: "18px", fontSize: "25px", fontFamily: "Noto Sans", fontWeight: 600 }}>{popupData?.registeredcount}</div>
                                    </center>
                                </Col>
                                <Col xs={4}>
                                    <Card style={{ border: "none", marginRight: "15px", background: "#DCEAF7", borderRadius: "10px", fontWeight: 700, fontFamily: "Noto Sans" }}>
                                        <center>
                                            <div style={{ fontSize: "28px" }}> {moment(popupData.bookingDate).format("DD")}</div>
                                            <div style={{ fontSize: "16px", marginTop: "-4px" }}> {moment(popupData.bookingDate).format("MMM")}</div>
                                            <div style={{ fontSize: "16px", marginTop: "0px" }} className="mb-2"> {popupData.time}</div>
                                        </center>
                                    </Card>
                                </Col>
                            </Row>
                            <div className="mb-1"></div>
                            <div className="sme-booking-slot-popup-bottom">
                                <div style={{ fontSize: "12px", padding: "3px 8px", cursor: "pointer" }} ></div>
                                <div style={{ fontSize: "12px", padding: "3px 8px" }}><img src={linktext} /><i>{popupData.agenda}</i>
                                </div>
                                <div style={{ paddingBottom: "3px" }}></div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>

                <style jsx>{`
            .events-container {
              margin-top: 10px;
              font-family:Noto Sans;
            }
            .event-box {
              background-color: #f9f9f9;
              border-radius: 8px;
              padding: 20px;
              margin-bottom: 15px;
              box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            }
          `}</style>
            </div>
            <div className="mb-bottom"></div>
            <BottomMenu />
        </div>
    );
};

export default ViewSmeEvents;

