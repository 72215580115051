import React, { Component } from "react";
import { Card, Col, Row } from "react-bootstrap";
import { Link } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import article from '../images/article1.png';
import nodata from '../images/no-data.png';
import moment from 'moment-timezone';
import BottomMenu from "../BottomMenu/BottomMenu";
import axios from 'axios';
import { config } from '../Config/Config';
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class ViewOpportunity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            opportunities: [],
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            isLoading: true
        }
        this.goBack = this.goBack.bind(this)
    }
    goBack = () => {
        window.history.back();
    }
    componentDidMount() {
        this.getOpportunities();
    }
    getOpportunities = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getActiveOpportunities', { headers: headers })
            .then(function (response) {
                if (response.data.status === true) {
                    var x = new Date()
                    var todaysDate = x.getDate() + '-' + (x.getMonth() + 1) + '-' + x.getFullYear();
                    // console.log("apply", todaysDate, moment(new Date()).format("DD-MM-YYYY"))
                    let opportunities = response.data.getActiveOpportunities.filter((data => moment(new Date()).format("DD-MM-YYYY") >= data.applyByDate.split("T")[0].split("-").reverse().join("-")))
                    // console.log("opp", opportunities)
                    self.setState({
                        opportunities: response.data.getActiveOpportunities,
                        isLoading: false
                    })
                } else {
                    console.log(response.data)
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };
    render() {
        const { opportunities, isLoading } = this.state;

        if (isLoading) {
            //  console.debug("renders: Loading...");
            return <div className="App"></div>;
        }
        return (
            <div >
                <div className="top_bar"><img src={loginlogo} className="login-logo" /></div>
                <div className="screen-topgap">
                    <div className="your-jitbits">Opportunities Here</div>
                    <div className="ml-3 mr-3 top-jit" >
                        {this.state.opportunities.length !== 0 ?
                            <div>
                                {this.state.opportunities.map((data, i) => (
                                    <Link to={{ pathname: `/opportunity/${data.id}`, data: this.state.opportunities }} className="ml-2 mr-2 link">
                                        <Card style={{ display: "flex" }} className="view_opp_card">
                                            <Row style={{ width: "100%", marginTop: "6px" }}>
                                                <Col xs={8}>
                                                    <div className="ml-2 favbits-title-new-opp" style={{ fontSize: "16px" }}>{data.company}</div>
                                                    {/* <img src={article} className="ml-2 favbits-article-jit" /> */}
                                                    {/* {data.company !== null && data.company > data.company.substring(0, 21) ?
                                                        <div className="ml-2 favbits-title-new-opp" style={{ fontSize: "16px" }}>{data.company.substring(0, 21)}...</div> :
                                                        <div className="ml-2 favbits-title-new-opp" style={{ fontSize: "16px" }}>{data.company}</div>} */}
                                                    <div style={{ display: "flex" }}><img alt="" src={config.userUrl + data.profilePicture} className="ml-2 opp-img-Author" /><span className="ml-2 opp-top" style={{ fontWeight: "600" }}>{data.name}</span></div>
                                                </Col>
                                                <Col xs={4}>
                                                    <div style={{ float: "right", color: "black", fontSize: "14px", fontWeight: "600", marginRight: "-12px" }}>{data.typeOfOpportunity}</div><br />

                                                    <div style={{ float: "right", color: "black", fontSize: "13px", fontWeight: "600", marginRight: "-12px" }}>{data.tenure}</div>


                                                    <div style={{ float: "right", color: "black", fontSize: "13px", fontWeight: "600", marginRight: "-12px" }}><b>{data.applyByDate.split("T")[0].split("-").reverse().join("-")}</b></div>
                                                    {/* <div style={{ display: "flex", marginTop: "15px" }}><img alt="" src={author} className="ml-2 jitbits-img-Author" /><span className="ml-2 jit-top">Dinesh</span>
                                                        </div> */}

                                                </Col>
                                            </Row>
                                        </Card>
                                    </Link>
                                ))}
                            </div> :
                            // <center className="mt-5">No Opportunities to display</center>
                            <div>
                                <img src={nodata} style={{ marginTop: "20%", width: "96%", marginLeft: "2%", marginRight: "2%" }} />
                                <center className="mt-4">No Opportunities to display</center>
                            </div>
                        }
                    </div>
                </div>
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div >
        )
    }
}