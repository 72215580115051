import React, { Component } from "react";
import { Button, Form, Col, OverlayTrigger, Popover, Card, Row, Image } from "react-bootstrap";
import { Link, Redirect } from 'react-router-dom';
import loginlogo from '../images/login-logo.png';
import activecircle from '../images/active-cir.svg';
import pendingcircle from '../images/pending-cir.svg';
import rejectedcircle from '../images/rejected-cir.svg';
import needinfocircle from '../images/needinfo-cir.svg';
import publishedcircle from '../images/published-cir.svg';
import nodata from '../images/no-data.png';
import axios from 'axios';
import { config } from '../Config/Config';
import BottomMenu from "../BottomMenu/BottomMenu";
import SimpleCrypto from "simple-crypto-js";
var key = process.env.REACT_APP_PASSWORD_ENCRYPTION_KEY
var simpleCrypto = new SimpleCrypto(key)

export default class AdvertisementStatus extends Component {
    constructor(props) {
        super(props);
        this.state = {
            token: simpleCrypto.decrypt(localStorage.getItem('junkStore')),
            overlay: false,
            adv: []

        }
    }

    componentDidMount() {
        this.getUserCreatedAds()
    }
    getUserCreatedAds = () => {
        let headers = {
            "Content-Type": 'application/json',
            "Authorization": "Bearer " + this.state.token,
            "applicationType": "mobile"
        }
        var self = this
        axios.get(config.userUrl + 'user/getAdvertisementStatusByUser', { headers: headers })
            .then(function (response) {
                //  console.log(response);
                if (response.data.status == true) {
                    self.setState({
                        adv: response.data.advertisement,
                        isLoading: false
                    })
                }
            })
            .catch(function (error) {
                console.log("Error");
            });
    };

    render() {
        const { isLoading, adv, overlay } = this.state;
        // if (isLoading) {
        //     return <div className="App"></div>;
        // }
        // const updatedDate = referral.map((i, idx) => {
        //     var a = new Date(i.updatedAt)
        //     var updated = a.getDate() + "-" + parseInt(a.getMonth() + 1) + "-" + a.getFullYear();
        //     return updated;
        // })
        return (
            <div>
                <div className="top_bar"><img alt="" src={loginlogo} className="login-logo mt-2" /></div>
                <div className="screen-topgap">
                    <div className="your-jitbits">Advertisements</div>
                    <div className="ml-3 mr-3 mt-3 top-jit">
                        {adv.length === 0 ?
                            // <center><h4>No Advertisements.</h4></center> 
                            <div>
                                <img src={nodata} style={{ marginTop: "16%", width: "96%", marginLeft: "2%", marginRight: "2%" }} />
                                <center className="mt-4">No Advertisements.</center>
                            </div>
                            :
                            <div>
                                {adv.map((data, i) => (
                                    <Link to={{ pathname: `/detailedadv/${data.id}`, data: adv }} className="link">
                                        <Card style={{ display: "flex",color:"black" }} className="adv-status-card">
                                            {/* <div className="mt-2"> 
                                        <span style={{ float: "left", marginLeft: "1px" }}>
                                        {data.status == 2 ? <img src={pendingcircle} className="adv-circles"/>
                                        : (data.status == 1 ? <img src={activecircle} className="adv-circles"/>
                                        : (data.status == 3 ? <img src={needinfocircle} className="adv-circles"/>
                                        : (data.status == 4 ?<img src={publishedcircle} className="adv-circles"/>
                                        :<img src={rejectedcircle} className="adv-circles"/>)))}</span><b className="ml-5">{data.campaignName}</b> 
                                        </div> */}
                                            {data.campaignName.length > 30 ?
                                                <OverlayTrigger
                                                    placement="top"
                                                    trigger={overlay ?
                                                        ""
                                                        : "click"}

                                                    rootClose
                                                    overlay={(
                                                        <Popover
                                                            id="test">
                                                            <Popover.Content >
                                                                <div style={{ marginTop: "-5px" }}><b>{data.campaignName}</b></div>
                                                            </Popover.Content>
                                                        </Popover>
                                                    )}>
                                                    {overlay ?
                                                        "" :
                                                        <div className="mt-2">
                                                            <span style={{ float: "left", marginLeft: "1px" }}>
                                                                {data.status == 2 ? <img src={pendingcircle} className="adv-circles" />
                                                                    : (data.status == 1 ? <img src={activecircle} className="adv-circles" />
                                                                        : (data.status == 3 ? <img src={needinfocircle} className="adv-circles" />
                                                                            : (data.status == 4 ? <img src={publishedcircle} className="adv-circles" />
                                                                                : <img src={rejectedcircle} className="adv-circles" />)))}</span><b className="ml-5">{data.campaignName.substring(0, 10)}...</b>
                                                        </div>}
                                                </OverlayTrigger>
                                                : <div className="mt-2">
                                                    <span style={{ float: "left", marginLeft: "1px" }}>
                                                        {data.status == 2 ? <img src={pendingcircle} className="adv-circles" />
                                                            : (data.status == 1 ? <img src={activecircle} className="adv-circles" />
                                                                : (data.status == 3 ? <img src={needinfocircle} className="adv-circles" />
                                                                    : (data.status == 4 ? <img src={publishedcircle} className="adv-circles" />
                                                                        : <img src={rejectedcircle} className="adv-circles" />)))}</span><b className="ml-5">{data.campaignName}</b>
                                                </div>}
                                        </Card>
                                    </Link>
                                ))}
                            </div>
                        }
                    </div>
                </div>
                <div className="mb-bottom"></div>
                <BottomMenu />
            </div>

        );
    };
}